<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { GroupButton } from '@/js/import/importView.js';

const title = ref('Maman solo')

useHead({
  title: title.value,
  meta: [
    { name: 'description', content: 'Une doula peut être particulièrement bénéfique pour soutenir une maman solo pour plusieurs raisons importantes.' },
    { name: 'keywords', content: 'parent isolé, maman solo, parentalité, soutien parental, soutien familial, soutien maternel, parentalité, parent, maman' },
  ],
})


</script>

<template>
  <article class="service-content" itemscope itemtype="https://schema.org/Article" itemprop="articleBody">

    <header class="container box-shadow-1 header" itemprop="headline">
      
      <h1>{{ title }}</h1>
      <p class="service-content__text_bold">
        Une doula peut être particulièrement bénéfique pour soutenir une maman 
        solo pour plusieurs raisons importantes : 
      </p>
    </header>

    <section class="container">

      <div class="logo">
          <img class="img-logo" src="@/assets/logo_nathalite_doula.png" alt="Logo de NatHalité Doula" />
        </div>
        
      <div class="service-content__block" >
        <ol class="list container">
            <li>
              <strong>Soutien émotionnel continu :</strong> 
              Les mamans solo peuvent se sentir isolées ou anxieuses pendant la grossesse 
              et l'accouchement. Une doula offre une présence constante et un soutien 
              émotionnel, ce qui peut réduire le stress et augmenter le bien-être émotionnel
              de la maman.
            </li>
            <li><strong>Aide pratique et soutien physique :</strong> Pendant le travail, 
              une doula peut fournir des techniques de confort telles que le massage, 
              la respiration et des positions de travail pour soulager la douleur et
               améliorer le confort physique de la maman. Cela est particulièrement 
               crucial lorsqu'il n'y a pas de partenaire ou d'accompagnant pour offrir 
               sun soutien physique.
            </li>  
            <li><strong>Encouragement à exprimer les préférences et les besoins :</strong>Une 
              doula peut aider la maman solo à formuler un plan de naissance qui reflète 
              ses souhaits et à communiquer efficacement avec le personnel médical pendant
              le travail et l'accouchement. Cela assure que ses choix soient respectés et 
              ses besoins soient pris en compte.
            </li>
            <li><strong>Support postnatal et transition à la parentalité : </strong>Après 
              la naissance, une doula peut aider avec des conseils sur l'allaitement, 
              les soins du nouveau-né, et d'autres aspects pratiques de la parentalité. 
              Cela peut être particulièrement utile pour une maman solo qui n'a pas de 
              réseau de soutien familial proche.
            </li>
            <li><strong>Renforcement de la confiance en soi : </strong>Une doula encourage 
              la maman à faire confiance à ses instincts maternels et à ses capacités 
              parentales, renforçant ainsi son sentiment de compétence et de sécurité 
              dans son rôle de parent solo.
            </li>
          </ol>
      </div>
      <div class="service-content__block">
        <p>
          En résumé, une <strong>doula</strong> apporte un <strong>soutien continu</strong>, 
          à la fois émotionnel et pratique, qui peut être essentiel pour une <strong>maman solo</strong>
           tout au long de sa <strong>grossesse</strong>, de <strong>l'accouchement</strong> 
           et au-delà. Elle joue un rôle crucial en offrant une présence rassurante et en 
           aidant la <strong>maman</strong> à se sentir <strong>soutenue</strong> et 
           <strong>confiante</strong> dans son parcours vers la <strong>parentalité</strong>.
        </p>    
      </div>
      <div class="service-end">
        <div class="row justify-center">
          <GroupButton />
        </div>
      </div>
    </section>


  </article>
</template>

<style lang="scss" scoped>
@import '../../styles/services_item.scss';
</style>

