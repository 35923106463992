<script setup>
import { useHead } from '@vueuse/head';

useHead({
    meta: [
        { name: 'robots', content: 'noindex' },
    ],
});



</script>

<template>
    <main id="privacy-confidality">

        <div class="title-block  header">
            <h1>Politique de confidentialité</h1>
        </div>

        <div class="container">
            <div>
                <h2>Qui sommes-nous ?</h2>
            </div>
            <p>L’adresse du Site officiel de <strong>Nathalié Doula 02 </strong>est : <a class="underline" href="https://nathalie-doula-02.fr">nathalie-doula-02.fr</a></p>
            <h2>Utilisation des données personnelles collectées</h2>
            <h2>Responsable de la conception éditoriale du site Internet</h2>
            <p><strong>Nathalie&nbsp; Vieville </strong><br><strong>14 cité Gambetta<br></strong>
                <strong>02610 Moÿ de l'Aisne</strong> <br><strong>nathalie.vieville@hotmail.fr<br>
                    EI inscrite à à l’INSEE&nbsp; sous le n° 925 008 500 00017<br></strong></p>
            <p><strong>Conception</strong><strong><br></strong>Directeur de publication : <strong>Nathalie Vieville</strong><br>Rédacteur en chef : <strong>Nathalie Vieville<br></strong>Animation éditoriale, gestion et mise à jour :&nbsp;<strong>Florent Vieville</strong></p>
            <h2>Développement et graphisme</h2>
            <p>Le site est édité par Florent Vieville, développeur indépendant (sans entreprise constituée).</p>
            <p>Adresse mail : <a href="mailto:florent.vieville03@gmail.com">florent.vieville03@gmail.com</a></p>
            <p>Système de Gestion de Contenu : <a href="https://vuejs.org">Vue.js </a> : Thème sur mesure et unique <br>Développement : <a href="https://dragon-l-air-web-developper.vercel.app">The dragon's lair web developper</a>&nbsp; – 3 bis Rue Victor Hugo 02610 Moÿ de l'Aisne - France.<br>Hébergement : <a href="https://www.ovh.com/fr/index.xml">OVH.com </a>– 2 rue Kellermann&nbsp; 59100 Roubaix – France.</p>
            <h2>Hébergement</h2>
            <p>OVH.Com- Tél: 08 203 203 63</p>
            <h2>Liens vers d’autre sites, droits d’auteur et informations nominatives</h2>
            <p>Ce site propose des liens vers d’autres sites. Le contenu de ces pages ne relève pas de l’éditeur qui vérifie néanmoins, avant la création de tout lien, la teneur du contenu des pages<br>Au cas où le contenu d’un site référencé causerait un préjudice à un tiers, ce dernier en informe l’éditeur dans les meilleurs délais qui procèdera au retrait des pages dont les contenus sont préjudiciables. L’ensemble de ce site Internet relève de la législation française et internationale sur le droit d’auteur et la propriété intellectuelle.<br>Tous les droits de reproduction sont réservés, y compris les représentations iconographiques et photographiques. La reproduction de tout ou partie de ce site Internet sur un support quel qu’il soit, est formellement interdite sauf autorisation expresse de l’éditeur. Les informations recueillies auprès des personnes par le biais du formulaire de demande d’information sont utilisées pour mieux répondre à vos attentes.<br>Tel que l’exige la loi n° 78-17 du 6 janvier 1978, nous vous informons que vos réponses sont facultatives , que votre défaut de réponse peut entraîner simplement une absence de réponse de la part de l’éditeur en cas d’information insuffisante.<br>Les informations envoyées sont destinées à l’éditeur qui traitera vos demandes d’info. Vous avez un droit total d’accès, de modification, de rectification et de suppression.</p>
            <h2>Cookies</h2>
            <p>L’utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation. Le cookie est un bloc de données qui ne permet pas d’identifier les utilisateurs mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site. Le paramétrage du logiciel de navigation permet d’informer de la présence de cookie et éventuellement, de la refuser. L’utilisateur dispose de l’ensemble des droits susvisés s’agissant des données à caractère personnel communiquées par le biais des cookies dans les conditions indiquées ci-dessus. Les utilisateurs de ce site sont tenus de respecter les dispositions de la loi n°78-17 du 6 janvier 1978 modifiée, relative à l’informatique, aux fichiers et aux libertés, dont la violation est passible de sanctions pénales. Ils doivent notamment s’abstenir, s’agissant des informations à caractère personnel auxquelles ils accèdent ou pourraient accéder, de toute collecte, de toute utilisation détournée d’une manière générale, de tout acte susceptible de porter atteinte à la vie privée ou à la réputation des personnes.</p>
            <h2>Limitation de responsabilité</h2>
            <p>L’utilisateur reconnaît avoir pris connaissance des présentes conditions d’utilisation et s’engage à les respecter. L’utilisateur de ce site reconnaît disposer de la compétence et des moyens nécessaires pour accéder et utiliser ce site. L’utilisateur de ce site reconnaît avoir vérifié que la configuration informatique utilisée ne contient aucun virus et qu’elle est en parfait état de fonctionnement. Tout est mis en œuvre pour offrir aux utilisateurs des informations et/ou des outils disponibles et vérifiés mais l’éditeur ne saurait être tenu pour responsable des erreurs, d’une absence de disponibilité des fonctionnalités et/ou de la présence de virus sur son site. Les informations fournies par ce site le sont à titre indicatif et ne sauraient dispenser l’utilisateur d’une analyse complémentaire et personnalisée. L’éditeur ne saurait garantir l’exactitude, la complétude, l’actualité des informations diffusées sur son site. En conséquence, l’utilisateur reconnaît utiliser ces informations sous sa responsabilité exclusive.</p>
            <h2>Propriété intellectuelle</h2>
            <p>La structure générale ainsi que les logiciels, textes, images animées ou non, son savoir-faire et tous les autres éléments composant le site sont la propriété exclusive de l’éditeur. Toute représentation totale ou partielle de ce site par quelle que personne que ce soit, sans l’autorisation expresse de l’éditeur est interdite et constituerait une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la propriété intellectuelle. Il en est de même des bases de données figurant, le cas échéant sur le site web qui sont protégées par les dispositions de la loi du 1er juillet 1998 portant transposition dans le Code de la propriété intellectuelle de la directive du 11 mars 1996 relative à la protection juridique des bases de données, et dont l’éditeur est producteur. Les marques de l’Editeur et de ses partenaires, ainsi que les logos figurant sur le site sont des marques déposées. Toute reproduction totale ou partielle de ces marques ou de ces logos effectués à partir des éléments du site sans l’autorisation expresse de l’éditeur est donc prohibée au sens du Code de la propriété intellectuelle. L’éditeur ne saurait être responsable de l’accès par les utilisateurs via les liens hypertextes mis en place dans le cadre du site internet en direction d’autres ressources présentes sur le réseau. Tout litige en relation avec l’utilisation de ce site est soumis au droit français. L’utilisateur reconnaît la compétence exclusive des tribunaux compétents.</p>
            <h2>Politique de confidentialité et d’utilisation des cookies</h2>
            <p>Dernière mise à jour : <strong>Juillet 2024</strong></p>
            <p>La présente politique de confidentialité définit et vous informe de la manière dont nous utilisons et protégeons les informations que vous nous transmettez, le cas échéant, lorsque vous utilisez le présent site accessible à partir de l’URL suivante :&nbsp;<strong>nathalitedoula02.fr</strong> (ci-après le « Site »).</p>
            <p>Veuillez noter que cette politique de confidentialité est susceptible d’être modifiée ou complétée à tout moment, notamment en vue de se conformer à toute évolution législative, règlementaire, jurisprudentielle ou technologique. Dans un tel cas, la date de sa mise à jour sera clairement identifiée en tête de la présente politique. Ces modifications engagent l’Utilisateur dès leur mise en ligne. Il convient par conséquent que l’Utilisateur consulte régulièrement la présente politique de confidentialité et d’utilisation des cookies afin de prendre connaissance de ses éventuelles modifications.</p>
            <p>I. DONNÉES PERSONNELLES</p>
            <p>D’une manière générale, il vous est possible de visiter le Site sans communiquer aucune information personnelle vous concernant. En toute hypothèse, vous êtes en aucune manière obligé de nous transmettre ces informations.</p>
            <p>Néanmoins, en cas de refus, il se peut que vous ne puissiez pas bénéficier de certaines informations ou services que vous avez demandés. A ce titre en effet, nous pouvons être amené dans certains cas à vous demander de renseigner vos nom, prénom, adresse mail, numéro de téléphone, entreprise et fonction (ci-après vos « Informations Personnelles »). En fournissant ces informations, vous acceptez expressément qu’elles soient traitées par nos soins, aux fins indiquées au point 2 ci-dessous ainsi qu’aux fins rappelées à la fin de chaque formulaire.</p>
            <p>Conformément au Règlement Général sur la Protection des Données (General Data Protection Régulation) adopté par le Parlement européen le 14 avril 2016, et à la Loi Informatique et Libertés du 6 janvier 1978 modifiée, nous vous informons des points suivants :</p>
            <ol>
            <li>Identité du responsable du traitement</li>
            </ol>
            <p>Le responsable du traitement est</p>
            <p><strong>N</strong><strong>atalie Vieville</strong><br><strong>14 cité Gambetta<br>
            </strong><strong>02610 Moÿ de l'Aisne</strong> <br><strong>nathalie.vieville@hotmail.fr<br>
                EI inscrite à à l’INSEE&nbsp; sous le n° 925 008 500 00017</strong></p>
            <ol start="2">
            <li>Finalités du traitement</li>
            </ol>
            <p>Nous sommes susceptibles de traiter vos Informations Personnelles :</p>
            <p>(a) aux fins de vous fournir les informations ou les services que vous avez demandé (notamment : l’envoi de la Newsletter, offre commerciale, livres blancs ou encore l’évaluation de votre niveau de conformité via un quizz) ; et/ou</p>
            <p>(b) aux fins de recueillir des informations nous permettant d’améliorer notre Site, nos produits et services (notamment par le biais de cookies) ; et/ou</p>
            <p>(c) aux fins de pouvoir vous contacter à propos de différents évènements nous étant &nbsp;relatifs, incluant notamment la mise à jour des produits et le support client.</p>
            <ol start="3">
            <li>Destinataires</li>
            </ol>
            <p>Nous sommes seul destinataire de vos Informations Personnelles. Celles-ci, que ce soit sous forme individuelle ou agrégée, ne sont jamais transmises à un tiers, nonobstant les sous-traitants auxquels nous faisons appel (vous trouverez de plus amples informations à leur sujet au point 7 ci-dessous). Ni nous, ni l’un quelconque de ses sous-traitants, ne procèdent à la commercialisation des données personnelles des visiteurs et Utilisateurs de son Site.</p>
            <ol start="4">
            <li>Durée de conservation</li>
            </ol>
            <p>Vos Informations Personnelles sont conservées par nous uniquement pour le temps correspondant à la finalité de la collecte tel qu’indiqué en 2 ci-dessus qui ne saurait en tout état de cause excéder 24 mois.</p>
            <ol start="5">
            <li>Droits Informatique et Libertés</li>
            </ol>
            <p>Vous disposez des droits suivants concernant vos Informations Personnelles, que vous pouvez exercer en nous écrivant à l’adresse postale mentionnée au point 1 ou en remplissant le formulaire ci-contre.</p>
            <p>o Droit d’accès et de communication des données</p>
            <p>Vous avez la faculté d’accéder aux Informations Personnelles qui vous concernent.</p>
            <p>Cependant, en raison de l’obligation de sécurité et de confidentialité dans le traitement des données à caractère personnel qui nous incombe, vous êtes informé que votre demande sera traitée sous réserve que vous rapportiez la preuve de votre identité, notamment par la production d’un scan de votre titre d’identité valide (en cas de demande par notre formulaire électronique dédié) ou d’une photocopie signée de votre titre d’identité valide (en cas de demande adressée par écrit).</p>
            <p>Nous vous informons que nous serons en droit, le cas échéant, de nous opposer aux demandes manifestement abusives (de par leur nombre, leur caractère répétitif ou systématique).</p>
            <p>Pour vous aider dans votre démarche, notamment si vous désirez exercer votre droit d’accès par le biais d’une demande écrite à l’adresse postale mentionnée au point 1, vous trouverez en cliquant sur le lien suivant un modèle de courrier élaboré par la Commission Nationale de l’Informatique et des Libertés (la « CNIL »).</p>
            <p><a href="https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces">https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces</a></p>
            <p>o Droit de rectification des données</p>
            <p>Au titre de ce droit, la législation vous habilite à demander la rectification, la mise à jour, le verrouillage ou encore l’effacement des données vous concernant qui peuvent s’avérer le cas échéant inexactes, erronées, incomplètes ou obsolètes.</p>
            <p>Également, vous pouvez définir des directives générales et particulières relatives au sort des données à caractère personnel après votre décès. Le cas échéant, les héritiers d’une personne décédée peuvent exiger de prendre en considération le décès de leur proche et/ou de procéder aux mises à jour nécessaires.</p>
            <p>Pour vous aider dans votre démarche, notamment si vous désirez exercer, pour votre propre compte ou pour le compte de l’un de vos proches décédé, votre droit de rectification par le biais d’une demande écrite à l’adresse postale mentionnée au point 1, vous trouverez en cliquant sur le lien suivant un modèle de courrier élaboré par la CNIL.</p>
            <p><a href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees">https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees</a></p>
            <p>o Droit d’opposition</p>
            <p>L’exercice de ce droit n’est possible que dans l’une des deux situations suivantes :</p>
            <p>Lorsque l’exercice de ce droit est fondé sur des motifs légitimes ; ou</p>
            <p>Lorsque l’exercice de ce droit vise à faire obstacle à ce que les données recueillies soient utilisées à des fins de prospection commerciale.</p>
            <p>Pour vous aider dans votre démarche, notamment si vous désirez exercer votre droit d’opposition par le biais d’une demande écrite adressée à l’adresse postale indiquée au point 1, vous trouverez en cliquant sur le lien suivant un modèle de courrier élaboré par la CNIL.</p>
            <p><a href="https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet">https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet</a></p>
            <ol start="6">
            <li>Délais de réponse</li>
            </ol>
            <p>Nous nous engageons à répondre à votre demande d’accès, de rectification ou d’opposition ou toute autre demande complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser 1 mois à compter de la réception de votre demande.</p>
            <ol start="7">
            <li>Prestataires habilités et transfert vers un pays tiers de l’Union Européenne</li>
            </ol>
            <p>Nous &nbsp;vous informons que nous pouvons avoir recours à ses prestataires habilités pour faciliter le recueil et le traitement des données que vous nous avez communiqué. Ces prestataires peuvent être situés en dehors de l’Union Européenne et ont communication des données recueillies par le biais des divers formulaires présents sur le Site (à l’exception du formulaire vous permettant d’exercer vos droits Informatique et Libertés qui sont proposé et exploité par nos soins).</p>
            <p>Nous nous sommes préalablement assurés de la mise en œuvre par ses prestataires de garanties adéquates et du respect de conditions strictes en matière de confidentialité, d’usage et de protection des données. Tout particulièrement, la vigilance s’est portée sur l’existence d’un fondement légal pour effectuer un quelconque transfert de données vers un pays tiers. A ce titre, l’un de nos prestataires est soumis à des règles internes d’entreprise (ou « Binding Corporate Rules ») qui ont été approuvées par la CNIL en 2016 quand les autres obéissent non seulement à des Clauses Contractuelles Types mais également au Privacy Shield.</p>
            <ol start="8">
            <li>Plainte auprès de l’autorité compétente</li>
            </ol>
            <p>Si vous considérez que nous ne respectons pas ses obligations au regard de vos Informations Personnelles, vous pouvez adresser une plainte ou une demande auprès de l’autorité compétente. En France, l’autorité compétente est la CNIL à laquelle vous pouvez adresser une demande par voie électronique en cliquant sur le lien suivant : <a href="https://www.cnil.fr/fr/plaintes/internet">https://www.cnil.fr/fr/plaintes/internet</a>.</p>
            <p>II. POLITIQUE RELATIVE AUX COOKIES</p>
            <p>Lors de votre première connexion sur le Site web, vous êtes avertis par un bandeau en bas de votre écran que des informations relatives à votre navigation sont susceptibles d’être enregistrées dans des fichiers dénommés « cookies ». Notre politique d’utilisation des cookies vous permet de mieux comprendre les dispositions que nous mettons en œuvre en matière de navigation sur notre site web. Elle vous informe notamment sur l’ensemble des cookies présents sur notre site web, leur finalité (partie I.) et vous donne la marche à suivre pour les paramétrer (partie II.)</p>
            <ol>
            <li>Informations générales sur les cookies présents sur le Site</li>
            </ol>
            <p>Nous, en tant qu’éditeur du présent site web, pourrons procéder à l’implantation d’un cookie sur le disque dur de votre terminal (ordinateur, tablette, mobile etc.) afin de vous garantir une navigation fluide et optimale sur notre site Internet.</p>
            <p>Les « cookies » (ou témoins de connexion) sont des petits fichiers texte de taille limitée qui nous permettent de reconnaître votre ordinateur, votre tablette ou votre mobile aux fins de personnaliser les services que nous vous proposons.</p>
            <p>Les informations recueillies par le biais des cookies ne permettent en aucune manière de vous identifier nominativement. Elles sont utilisées exclusivement pour nos besoins propres afin d’améliorer l’interactivité et la performance de notre site web et de vous adresser des contenus adaptés à vos centres d’intérêts. Aucune de ces informations ne fait l’objet d’une communication auprès de tiers sauf lorsque nous aurons obtenu au préalable votre consentement ou bien lorsque la divulgation de ces informations est requise par la loi, sur ordre d’un tribunal ou toute autorité administrative ou judiciaire habilitée à en connaître.</p>
            <p>Pour mieux vous éclairer sur les informations que les cookies identifient, vous trouverez ci-dessous un tableau listant les différents types de cookies susceptibles d’être utilisés sur le Site, leur nom, leur finalité ainsi que leur durée de conservation.</p>
            <ol start="2">
            <li>Configuration de vos préférences sur les cookies</li>
            </ol>
            <p>Vous pouvez accepter ou refuser le dépôt de cookies à tout moment</p>
            <p>Lors de votre première connexion sur le Site, une bannière présentant brièvement des informations relatives au dépôt de cookies et de technologies similaires apparaît en bas de votre écran. Cette bannière vous avertit qu’en poursuivant votre navigation sur le Site (en chargeant une nouvelle page ou en cliquant sur divers éléments du site par exemple), vous acceptez le dépôt de cookies sur votre terminal. Vous êtes également réputé avoir donné votre accord au dépôt de cookies en cliquant sur l’icône « X » à droite de la bannière figurant en bas de votre écran.</p>
            <p>Selon le type de cookie en cause, le recueil de votre consentement au dépôt et à la lecture de cookies sur votre terminal peut être impératif.</p>
            <p>2.1 Les cookies exemptés de consentement</p>
            <p>Conformément aux recommandations de la Commission Nationale de l’Informatique et des Libertés (CNIL), certains cookies sont dispensés du recueil préalable de votre consentement dans la mesure où ils sont strictement nécessaires au fonctionnement du site internet ou ont pour finalité exclusive de permettre ou faciliter la communication par voie électronique. Il s’agit notamment des cookies d’identifiant de session, d’authentification, de session d’équilibrage de charge ainsi que des cookies de personnalisation de votre interface. Ces cookies sont intégralement soumis à la présente politique dans la mesure où ils sont émis et gérés par nous.</p>
            <p>2.2 Les cookies nécessitant le recueil préalable de votre consentement</p>
            <p>Cette exigence concerne les cookies émis par des tiers et qui sont qualifiés de « persistants » dans la mesure où ils demeurent dans votre terminal jusqu’à leur effacement ou leur date d’expiration.</p>
            <p>De tels cookies étant émis par des tiers, leur utilisation et leur dépôt sont soumis à leurs propres politiques de confidentialité dont vous trouverez un lien ci-dessous. Cette famille de cookie regroupe les cookies de mesure d’audience (notamment Google Analytics), les cookies publicitaires (auxquels nous n’avons pas recours) ainsi que les cookies de partage de réseaux sociaux (notamment de Facebook, YouTube, Twitter et LinkedIn).</p>
            <p>Les cookies de mesure d’audience et de référencement établissent des statistiques concernant la fréquentation et l’utilisation de divers éléments du site web (comme les contenus/pages que vous avez visité). Ces données participent à l’amélioration de l’ergonomie du Site. Un outil de mesure d’audience est utilisé sur le présent site internet :</p>
            <p>o Google Analytics dont la politique de confidentialité est disponible (uniquement en anglais) à partir du lien suivant : <a href="https://support.google.com/analytics/answer/6004245?hl=fr">https://support.google.com/analytics/answer/6004245?hl=fr .</a></p>
            <p>o Microsoft Bing</p>
            <p>Nous utilise le suivi de conversion de Microsoft (Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA). Microsoft Bing utilise des cookies. Si l’Utilisateur ne souhaite pas participer au processus de suivi, il peut configurer son navigateur en désactivant l’installation automatique de cookies.</p>
            <p>Plus d’informations sur la politique de confidentialité de Microsoft : <a href="https://privacy.microsoft.com/fr-fr/privacystatement">https://privacy.microsoft.com/fr-fr/privacystatement</a> .</p>
            <p>Les cookies de partage des réseaux sociaux sont émis et gérés par l’éditeur du réseau social concerné. Sous réserve de votre consentement, ces cookies vous permettent de partager facilement une partie du contenu publié sur le Site, notamment par l’intermédiaire d’un « bouton » applicatif de partage selon le réseau social concerné. Quatre types de cookies de partage des réseaux sociaux sont présents sur le Site :</p>
            <p>o Facebook, dont vous pouvez consulter la politique en matière de cookies en cliquant sur le lien suivant : <a href="https://fr-fr.facebook.com/policies/cookies/">https://fr-fr.facebook.com/policies/cookies/</a></p>
            <p>o LinkedIn, dont vous trouverez la politique relative aux cookies en cliquant sur le lien suivant : <a href="https://www.linkedin.com/legal/cookie-policy?_l=fr_FR">https://www.linkedin.com/legal/cookie-policy?_l=fr_FR</a></p>
            <p>o Twitter, dont vous trouverez les options dédiées au contrôle ou à la restriction de l’utilisation des cookies ainsi que la politique d’utilisation des cookies : <a href="https://support.twitter.com/articles/20170518">https://support.twitter.com/articles/20170518#</a></p>
            <p>o YouTube, dont vous trouverez l’aide nécessaire pour supprimer les cookies à partir du navigateur Google Chrome en cliquant sur le lien suivant : <a href="https://support.google.com/youtube/answer/32050?hl=fr">https://support.google.com/youtube/answer/32050?hl=fr</a> mais également la politique complète en matière de cookies à partir du lien suivant : <a href="https://www.google.fr/intl/fr/policies/technologies/cookies/">https://www.google.fr/intl/fr/policies/technologies/cookies/</a></p>
            <p>2.3 Vous disposez de divers outils de paramétrage des cookies</p>
            <p>La plupart des navigateurs Internet sont configurés par défaut de façon à ce que le dépôt de cookies soit autorisé. Votre navigateur vous offre l’opportunité de modifier ces paramètres standards de manière à ce que l’ensemble des cookies soit rejeté systématiquement ou bien à ce qu’une partie seulement des cookies soit acceptée ou refusée en fonction de leur émetteur.</p>
            <p>ATTENTION : Nous attirons votre attention sur le fait que le refus du dépôt de cookies sur votre terminal est néanmoins susceptible d’altérer votre expérience d’utilisateur ainsi que votre accès à certains services ou fonctionnalités du présent site web. Le cas échéant, nous déclinons toute responsabilité concernant les conséquences liées à la dégradation de vos conditions de navigation qui interviennent en raison de votre choix de refuser, supprimer ou bloquer les cookies nécessaires au fonctionnement du site. Ces conséquences ne sauraient constituer un dommage et vous ne pourrez prétendre à aucune indemnité de ce fait.</p>
            <p>Votre navigateur vous permet également de supprimer les cookies existants sur votre terminal ou encore de vous signaler lorsque de nouveaux cookies sont susceptibles d’être déposés sur votre terminal. Ces paramétrages n’ont pas d’incidence sur votre navigation mais vous font perdre tout le bénéfice apporté par le cookie.</p>
            <p>Veuillez ci-dessous prendre connaissance des multiples outils mis à votre disposition afin que vous puissiez paramétrer les cookies déposés sur votre terminal.</p>
            <p>2.4 Le paramétrage de votre navigateur Internet</p>
            <p>Chaque navigateur Internet propose ses propres paramètres de gestion des cookies. Pour savoir de quelle manière modifier vos préférences en matière de cookies, vous trouverez ci-dessous les liens vers l’aide nécessaire pour accéder au menu de votre navigateur prévu à cet effet :</p>
            <p>Chrome : <a href="https://support.google.com/chrome/answer/95647?hl=fr">https://support.google.com/chrome/answer/95647?hl=fr</a></p>
            <p>Firefox : <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">https://support.mozilla.org/fr/kb/activer-desactiver-cookies</a></p>
            <p>Edge / Internet Explorer : <a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11</a></p>
            <p>Opéra : <a href="http://help.opera.com/Windows/10.20/fr/cookies.html">http://help.opera.com/Windows/10.20/fr/cookies.html</a></p>
            <p>Safari : <a href="https://support.apple.com/fr-fr/guide/deployment/depf7d5714d4/web">https://support.apple.com/fr-fr/guide/deployment/depf7d5714d4/web</a></p>
            <p>Pour de plus amples informations concernant les outils de maîtrise des cookies, vous pouvez consulter le site internet de la CNIL : <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</a>.</p>
            <p>Pour toute interrogation ou demande complémentaire d’informations relative à la présente politique des cookies, merci de bien vouloir nous contacter par le biais de notre formulaire.</p>
            <p>III. POLITIQUE DE DÉSABONNEMENT</p>
                <p>Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre commande, des nouvelles de l’entreprise de façon occasionnelle, des informations sur des produits liés, etc. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.</p>
                <p>IV. CONSENTEMENT</p>
                <p>En utilisant notre site, vous consentez à notre politique de confidentialité.</p>
        </div>

      
    </main>
</template>



<style lang="scss" scoped>

</style>