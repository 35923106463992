import { createApp } from 'vue'
import './styles/main.scss'
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import App from './App.vue'
import router from './router'
import logo from '@/assets/logo_nathalite_doula.png'
import { createHead } from '@vueuse/head';

const head = createHead();

createApp(App).provide('$logo',logo).use(router).use(head).mount('#app')