<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { ScrollToTopButton } from '@/js/import/importView.js';
import Modal from '@/views/Modal.vue';
import { useModal } from '@/js/use/useModal';
import mutuelleNostrumCare from '@/assets/forfaits/mutuelle_nostrum_care.jpg';
import mutuelleNostrumCare2 from '@/assets/forfaits/mutuelle_nostrum_care2.jpg';

const title = ref('Mes forfaits');
const { isModalOpen, modalImage, openModal, closeModal } = useModal();
const forfaits = ref([
  {
    title: 'Accompagnement prénatal',
    price: '1h30 à 60€ ou 2h à 80€',
    description: 'désire d\'enfant - grossesse - accouchement - écoute - bienveillance',
    image: new URL('@/assets/forfaits/forfait_accompagnement_prenatal.png', import.meta.url).href,
    alt: 'icon accompagnement prénatal'
  },
  {
    title: 'Accompagnement Postnatal',
    price: '2h à 80€ ou 3h à 100€',
    description: 'écoute - soutien émotionnel - soutien physique - soutien logistique - soutien maternel',
    image: new URL('@/assets/forfaits/forfait_accompagnement_postnatal.png', import.meta.url).href,
    alt: 'icon accompagnement postnatal'
  },
  {
    title: 'Forfait Doula',
    price: '4 rencontres d\'1h30 à 220€',
    description: 'Forfait prénatal et postnatal',
    image: new URL('@/assets/forfaits/forfait_forfait_doula.png', import.meta.url).href,
    alt: 'icon forfait doula'
  },
]);

useHead({
  title: 'Mes forfaits',
  meta: [
    { name: 'description', content: 'Découvrez l\'accompagnement par une doula pendant l\'accouchement avec NatHalité Doula. Un soutien émotionnel et physique pour une naissance sereine.' },
    { name: 'keywords', content: 'accompagnement doula, accouchement, soutien émotionnel, NatHalité Doula, prix, forfait, doula' },
  ],
  script: [
    { src: 'https://schema.org/WebPageElement', type: 'application/ld+json' },
    { src: 'https://schema.org/ItemList', type: 'application/ld+json' },
    { src: 'https://schema.org/Offer', type: 'application/ld+json' },
    { src: 'https://schema.org/FAQPage', type: 'application/ld+json' },
  ],

});

</script>

<template>
  <main id="forfaits" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
    <head>
      <meta charset="UTF-8">
      <meta name="description" content="Découvrez l'accompagnement par une doula pendant l'accouchement avec NatHalité Doula. Un soutien émotionnel et physique pour une naissance sereine.">
      <meta name="keywords" content="accompagnement doula, accouchement, soutien émotionnel, NatHalité Doula, prix, forfait, doula">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>{{ title }}</title>
    </head>

    <div class="parallax">

    <header class="container box-shadow-1 header header-parallax-bg" itemprop="headline">
      <h1 class="text-light">{{ title }}</h1>
    </header>

    <section>
      <div class="forfaits-container container" itemprop="mainEntity" itemscope itemtype="https://schema.org/ItemList">
        <meta itemprop="name" content="Liste des forfaits" />
        <div class="forfaits-group">
          <div class="forfaits-card" v-for="forfait in forfaits" :key="forfait.title" itemprop="itemListElement" itemscope itemtype="https://schema.org/Offer">
            <div class="forfaits-card-header">
              <h5 class="forfait-card-title" itemprop="name">{{ forfait.title }}</h5>
              <img :src="forfait.image" :alt="forfait.alt" class="forfait-icon" itemprop="image" /> <!-- Ajout de l'icône -->
            </div>
            <div class="separator"></div>
            <div class="forfaits-card-body">
              <div class="forfaits-card-body-row">
                <div class="forfait-row forfait-price-block">
                  <p class="card-text" itemprop="price">{{ forfait.price }}</p>
                </div>
                <div class="separator"></div>
                <div class="forfait-row forfait-description-block">
                  <p class="card-text" itemprop="description">{{ forfait.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>

    <article id="forfait-info" class="container box-shadow-1" itemprop="mainEntity" itemscope itemtype="https://schema.org/FAQPage">
      <h2 itemprop="headline">Comment payer ?</h2>
      <p itemprop="text">
        J'accepte d'être payer en plusieurs fois , tout accompagnement doit être payer le jour même du rdv 
        Si c'est un forfait , le paiement de chaque mensualité sera à payer avant les rdv d'accompagnement. 
        J'accepte d'être payer par virement bancaire, par paylib, en espèces et avec le site chèque emploi service 
        ,une fiche vous sera remis avec les coordonnées pour ce service. Nous pourrons l'effectuer les modalités CESU+ 
        ensemble. 
      </p>
      <p itemprop="text">Elsee rembourse la médecine douce pour les femmes.( La photo résume un peu .)
        Abonnez-vous à Elsee et votre accompagnement doula vous sera remboursé
      </p>
      <p itemprop="text">
        Je te joins des photos avec les explications. La mutuelle nostrum Care rembourse les frais d'accompagnement avec une Doula. (Voir photo)
      </p>
      <p itemprop="text">
        Puis la Doula peut être payer en chèque emploi service, vous bénéficiez d'un crédit d'impôt. (Voir photo )
      </p>
      <div class="forfait-img-container">
        <img class="responsive-img forfait-img" :src="mutuelleNostrumCare" alt="mutuelle nostrum care" @click="openModal(mutuelleNostrumCare)" />
        <div class="forfait-img-container">
        <img class="responsive-img forfait-img" :src="mutuelleNostrumCare2" alt="mutuelle nostrum care" @click="openModal(mutuelleNostrumCare2)" />
      </div>
      </div>
    </article>

    <!-- Scroll to Top button -->
    <div>
      <ScrollToTopButton/>
    </div>

    <!-- Modale pour afficher l'image en grand format -->
    <Modal :isOpen="isModalOpen" :imageSrc="modalImage" @close="closeModal" />
  </main>
</template>


<style lang="scss" scoped>



.parallax {
    background-image: url('@/assets/forfaits/forfait-background.jpg');
    z-index: -1;

}

    .forfaits-container {
      background-color: #5e0c2969;
      width: 100%;
      height: auto;

    }


#forfaits {

  .forfaits-group {
    @include flex-row;
    flex-wrap: wrap;
    justify-content: space-between; /* Espace entre les cartes */
    gap: rem(1); /* Espace entre les cartes */
    
    



    .forfaits-card {
      flex: 1 1 calc(30% - 1rem); /* 3 cartes par ligne */
      max-width: calc(30% - 1rem); /* Limite la largeur maximale des cartes */
      min-height: rem(300);
      border: 2px solid $purple;
      border-radius: 5px;
      @extend .bg-body;
      box-shadow: 0 0 10px 0 #780462;
      margin: rem(8); /* Ajout d'un espace entre les cartes */

      .separator {
          width: 100%;
          height: 1px;
          @extend .bg-purple;
          padding: 0;
        }

      .forfaits-card-header {
        padding: rem(8) rem(16);
        background-color: $bg-section-1;
        color: $purple;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        @include flex-column;
        justify-content: center;
        align-items: center;
        gap: rem(16);
        border-bottom: 1px solid $purple;

        .forfait-icon {
        width: rem(90); 
        height: rem(90); 
        margin-bottom: rem(16);
      }

        .forfait-card-title {
          @include font-size-responsive(0.8rem, 1.2rem);
          margin-bottom : rem(0.5);
        }
      }

      .forfaits-card-body {
        font-size: 1.1rem;
        color: #333;
        width: 100%;
        @include flex-column;

        justify-content: center;
        text-align: center;
        flex: 1;

        .forfaits-card-body-row {
          text-align: center;

          .forfait-price-block {

            background-color: white;
            padding: rem(8);

          }

          .forfait-row {
            gap: 0.5rem;
          }
        }



        .forfait-description-block {
          padding:  rem(16);
        }
      }
    }
  }

  #forfait-info {
    @include flex-column;
    gap: 2rem;
    justify-content: center;
    font-size: 1.2rem;
    background-color: $bg-section-3;
  }

  .forfait-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    
    .forfait-img {
      max-width: 180px;
      cursor: pointer;
      transition: transform 0.8s ease;
    }

    .forfait-img:hover {
      transform: scale(1.1);
    }
  }

  /* Media Queries pour les tailles d'écran */
  @media (min-width: 1024px) {
    .forfaits-group {
      .forfaits-card {
        flex: 1 1 calc(30% - 2rem); /* 3 cartes par ligne */
        max-width: calc(30% - 2rem); /* Limite la largeur maximale des cartes */
      }
    }

    .forfaits-card-header .forfait-card-title {
      font-size: 1.5rem; /* Taille de police plus grande pour les grands écrans */
    }

    .forfaits-card-body {
      font-size: 1.2rem; /* Taille de police plus grande pour les grands écrans */
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .forfaits-group {
      .forfaits-card {
        flex: 1 1 calc(45% - 1rem); /* 2 cartes par ligne */
        max-width: calc(45% - 1rem); /* Limite la largeur maximale des cartes */
      }
    }

    .forfaits-card-header .forfait-card-title {
      font-size: 1.3rem; /* Taille de police moyenne pour les écrans moyens */
    }

    .forfaits-card-body {
      font-size: 1.1rem; /* Taille de police moyenne pour les écrans moyens */
    }
  }

  @media (max-width: 767px) {
    .forfaits-group {
      .forfaits-card {
        flex: 1 1 calc(100% - 1rem); /* 1 carte par ligne */
        max-width: calc(100% - 1rem); /* Limite la largeur maximale des cartes */
      }
    }

    .forfaits-card-header .forfait-card-title {
      font-size: 1.2rem; /* Taille de police plus petite pour les petits écrans */
    }

    .forfaits-card-body {
      font-size: 1rem; /* Taille de police plus petite pour les petits écrans */
    }
  }
}

.forfait-icon {
  width: 30px; /* Ajustez la taille de l'icône selon vos besoins */
  height: 30px; /* Ajustez la taille de l'icône selon vos besoins */
  margin-left: 10px; /* Espace entre le titre et l'icône */
}
</style>