<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { GroupButton } from '@/js/import/importView.js';
import ServiceEnd from '@/views/ServiceEnd.vue';

const title = ref('Rituel rebozo')

useHead({
  title: title.value,
  meta: [
    { name: 'description', content: 'Le rituel rebozo est une pratique traditionnelle mexicaine qui est souvent utilisée pendant et après la grossesse, bien que ses applications puissent aller au-delà de cette période spécifique de la vie d\'une femme.' },
    { name: 'keywords', content: 'rituel rebozo, doula, natation, grossesse, accouchement, bébé, guérison, guérisseur, sages-femme, mexique, doula, natation, grossesse, accouchement, bébé, guérison, guérisseur, sages-femme, mexique' }
  ]
});

</script>

<template>
  <div>
    <div class="background-scroll-container">
        <img src="@/assets/service-img/rituel-rebozo-1.jpg" itemprop="image" alt="Image rituel rebozo" style="display: none;" /> <!-- Image pour SEO -->
    </div>

    <article class="service-content parallax" itemscope itemtype="https://schema.org/Article" itemprop="articleBody">

      <header class="container box-shadow-1 header">
        
        <h1>{{ title }}</h1>
        <p class="service-content__text_bold" itemprop="description">
          Le <strong>rituel rebozo</strong> est une <strong>pratique traditionnelle mexicaine</strong> qui est souvent utilisée pendant
          et après la grossesse, bien que ses applications puissent aller au-delà de cette période 
          spécifique de la vie d'une femme. Il est conçu pour offrir des bienfaits physiques, émotionnels
          et spirituels, en utilisant un châle mexicain traditionnel appelé "rebozo". Voici un 
          aperçu de ce qu'implique ce rituel:
        </p>

        
      </header>

      <div class="container">
        <div class="logo">
            <img class="img-logo" src="@/assets/logo_nathalite_doula.png" alt="Logo de NatHalité Doula" itemprop="image"/>
          </div>
      </div>
      <div class="container">
              
        <div class="service-content__block-bg ">
        
          <section class="container" itemprop="articleBody">
          


          <div class="service-content__block">

            <div class="float-left-block">
              <h2>Origines et Signification</h2>
              <img src="@/assets/service-img/rituel-rebozo-2.jpg" class="header__article-img" itemprop="image" alt="Image rituel rebozo" />

            <p class="service-content__text_bold" itemprop="description">
              Le <strong>rebozo</strong> est un châle en tissu utilisé par les <strong>femmes</strong> au <strong>Mexique</strong> depuis des 
              siècles. Il a de multiples usages, y compris porter des <strong>bébés</strong>, transporter des objets et, 
              dans le cadre du <strong>rituel Rebozo</strong>, pour les soins du corps. Le <strong>rituel</strong> est enraciné dans les
              traditions de guérison des <strong>sages-femmes</strong> et des <strong>guérisseurs mexicains</strong>.
            </p>    

            </div>


          </div>


          <div class="service-content__block" ><h2>Applications du Rituel Rebozo</h2></div>

          <div class="service-content__block" >
            <ol class="list" >
              <li><strong>Applications du Rituel Rebozo</strong>
                <ul class="list">
                  <li><strong>Soulagement physique : </strong> Assurez-vous que l'endroit est tranquille et sans perturbations.</li>
                  <li><strong>Lumière tamisée :</strong>  Le rebozo peut être utilisé pour masser et soutenir le ventre, aidant à
                    soulager les douleurs et les tensions.</li>
                  <li><strong>Préparation à l'accouchement :</strong> Des techniques spécifiques utilisant le rebozo peuvent aider à 
                    positionner le bébé pour l'accouchement.</li>
                </ul>
              </li>
              <li><strong>Température de l'eau</strong>
                <ul class="list">
                  <li><strong>Eau tiède :</strong> La température de l'eau doit être entre 36 et 37°C.</li>
                </ul>
              </li>
              <li><strong>Matériel nécessaire</strong>
                <ul class="list">
                  <li><strong>Une baignoire</strong> suffisamment grande pour vous deux.</li>
                  <li><strong>Un thermomètre</strong> pour vérifier la température de l'eau.</li>
                  <li><strong>Des serviettes</strong> douces et chaudes.</li>
                  <li><strong>Des produits</strong> de bain adaptés aux bébés (savon doux et shampoing hypoallergénique).</li>
                </ul>
              </li>
            </ol>
          </div>
        </section>

        <section class="container" >
          <div class="service-content__block">
            <h2>Déroulement du bain rituel d'accueil</h2>
          </div>

          <div class="service-content__block">
            <ol class="list">
              <li><strong>Préparation</strong></li>
              <ul class="list">
                <li>
                  <strong>Votre préparation :</strong> Avant de commencer, assurez-vous que vous êtes propre. Prenez une douche rapide
                  pour enlever toute saleté ou huile corporelle.
                </li>
                <li>
                  <strong>Préparation du bébé :</strong> Déshabillez doucement votre bébé en lui parlant calmement et en le rassurant.
                </li>
              </ul>
              <li><strong>Entrez dans l'eau</strong></li>
              <ul class="list">
                <li>
                  <strong>Vous d'abord :</strong> Entrez d'abord dans la baignoire et asseyez-vous confortablement.
                </li>
                <li>
                  <strong>Votre bébé ensuite :</strong> aites-vous aider par votre partenaire ou votre doula pour vous passer le bébé. 
                  Tenez-le fermement en soutenant sa tête et son cou.
                </li>
              </ul>
              <li><strong>Bain ensemble</strong></li>
              <ul class="list">
                <li>
                  <strong>Contact peau à peau :</strong> Maintenez un contact peau à peau en posant le bébé contre votre poitrine.
                  Cela aide à réguler sa température et à le rassurer.
                </li>
                <li><strong>Douceur et lenteur :</strong> Utilisez vos mains pour doucement asperger de l'eau sur le bébé. Lavez-le
                  doucement en utilisant des mouvements lents et apaisants.
                </li>
              </ul>
              <li><strong>Interaction</strong></li>
              <ul class="list">
                <li>
                  <strong>Parlez et chantez :</strong>  Parlez doucement ou chantez à votre bébé pendant le bain. 
                  Cela renforce votre lien et crée une atmosphère sereine.
                </li>
                <li><strong>Regardez dans les yeux :</strong> Maintenez un contact visuel fréquent avec votre bébé pour le rassurer.</li>
              </ul>
              <li><strong>Sortie du bain :</strong></li>
              <ul class="list">
                <li>
                  <strong>Transfert sécurisé :</strong>Faites-vous aider pour sortir le bébé de l'eau.
                  Enveloppez-le immédiatement dans une serviette chaude.
                </li>
                <li><strong>Vous sortez ensuite :</strong> Sortez ensuite de la baignoire en veillant à ne pas glisser.</li>
              </ul>
              <li><strong>Après bain</strong></li>
              <ul class="list">
                <li>
                  <strong>Séchage et habillage du bébé :</strong> Séchez le bébé avec des tapotements doux et habillez-le 
                  avec des vêtements confortables.
                </li>
                <li><strong>Temps de câlin :</strong> Prenez un moment pour câliner et réconforter votre bébé après le bain.</li>  
              </ul>
            </ol>
          </div>

          <div class="service-content__block">
            <h3>Conseils supplémentaires</h3>
          </div>

          <div class="service-content__block">
            <ul class="list">
                <li>
                  <strong>Fréquence :</strong> Un bain rituel de ce type peut être fait une fois par semaine ou selon 
                  votre convenance et le confort de votre bébé.
                </li>
                <li><strong>Sécurité avant tout :</strong> Assurez-vous que la baignoire est sécurisée et que vous avez
                  tout ce dont vous avez besoin à portée de main.
                </li>  
                <li><strong>Produits naturels :</strong> Utilisez des produits de bain naturels et sans parfum pour éviter
                  les irritations cutanées.
                </li>
              </ul>
          </div>

          <div class="service-content__block">
            <p>
              Le <strong>bain rituel</strong> partagé est une <strong>expérience profondément enrichissante</strong> pour vous et votre bébé. Il favorise une connexion émotionnelle forte et offre un moment de détente et de douceur pour vous deux. il y aura des surprise avant et après le bain.
              je vous expliquerai lors d'un entretien télephonique.
            </p>    
          </div>
          
        </section>

      
        </div>

        <div class="service-end container">
          <ServiceEnd 
            :price="300" 
            description="massage à 4 mains rituel rebozo" 
            infoText="Pour plus d'information, n'hésitez pas à nous contacter" 
          />
        </div>



      </div>


    </article>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/services_item.scss';
@import '../../styles/paralax.scss';







.parallax {
  background-image: url('@/assets/service-img/rituel-rebozo-1.jpg');
}



.float-left-block {
  
  @include media-breakpoint-down(md) {
    width: 100%;
    flex-direction: column-reverse;
    display: flex;

  }


  
.header__article-img {
  width: 100%;
  height: 100%;
  max-width: rem(300);
  max-height: rem(300);
  margin: rem(20) auto;
  float: right;

  @include media-breakpoint-down(md) {
    float: none;
    max-width: rem(200);
    max-height: rem(200);
  }
}

}

.float-left-block p {
  margin-top: rem(40);
  line-height: 3;
  overflow-wrap: normal;

  @include media-breakpoint-down(md) {
    margin-top: rem(20);
    line-height: 1.5;
    width: 100%;
  }
}

.price span {
  color: white!important;
}

</style>