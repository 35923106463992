import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue';
import Home from './components/Home.vue';
import NotFound from './components/404_error.vue';
import Contact from './components/Contact.vue';
import ConfidentialityPolicy from '@/components/ConfidentialityPolicy.vue';
import Services from './components/Services.vue';
import RituelRebozoService from './components/services-path/rituel_rebozo.vue';
import MamansoloService from './components/services-path/maman_solo.vue';
import GroupeDeParoleService from './components/services-path/groupe_parole.vue';
import DesireEnfantService from './components/services-path/desire_enfant.vue';
import GrossesseService from './components/services-path/grossesse.vue';
import DeuilPerinatalService from './components/services-path/deuil_perinatal.vue';
import TenteRougeService from './components/services-path/tente_rouge.vue';
import BainRituelService from './components/services-path/bain_rituel.vue';
import RefermetureDeBassinService from './components/services-path/refermeture_de_bassin.vue';
import SoinOuvertureService from './components/services-path/soin_ouverture.vue';
import Forfaits from './components/Forfaits.vue';
import { scrollToSection, smoothScrollTo } from '@/js/use/scrollUtils';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/confidentiality-policy',
    name: 'ConfidentialityPolicy',
    component: ConfidentialityPolicy
  },
  {
    path: '/services',
    component: Services,
    children: [
      {
        path: 'maman-solo',
        name: 'Maman-solo',
        component: MamansoloService,
        meta: { title: 'Maman solo' }
      },
      {
        path: 'groupe-de-parole',
        name: 'GroupeDeParole',
        component: GroupeDeParoleService,
        meta: { title: 'Groupe de Parole' }
      },
      {
        path: 'desire-enfant',
        name: 'DesireEnfant',
        component: DesireEnfantService,
        meta: { title: 'Désir d\'Enfant' }
      },
      {
        path: 'grossesse',
        name: 'Grossesse',
        component: GrossesseService,
        meta: { title: 'Grossesse' }
      },
      {
        path: 'deuil-perinatal',
        name: 'DeuilPerinatal',
        component: DeuilPerinatalService,
        meta: { title: 'Deuil Périnatal' }
      },
      {
        path: 'tente-rouge',
        name: 'TenteRouge',
        component: TenteRougeService,
        meta: { title: 'Tente rouge' } 
      },
      {
        path: 'bain-rituel',
        name: 'BainRituel',
        component: BainRituelService,
        meta: { title: 'Bain Rituel' }
      },
      {
        path: 'rituel-rebozo',
        name: 'RituelRebozo',
        component: RituelRebozoService,
        meta: { title: 'Rituel Rebozo' }
      },
      {
        path: 'refermeture-de-bassin',
        name: 'RefermetureDeBassin',
        component: RefermetureDeBassinService,
        meta: { title: 'Refermeture de bassin' }
      },
      {
        path: 'soin-ouverture',
        name: 'SoinOuverture',
        component: SoinOuvertureService,
        meta: { title: 'Soin d\'ouverture' }
      },
    ]
  },
  {
    path: '/forfaits',
    name: 'Forfaits',
    component: Forfaits,
    meta: { title: 'Mes forfaits' }
  }, 
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Me contacter' }
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.query.scrollTo) {
      return new Promise((resolve) => {
        setTimeout(() => {
          scrollToSection(to.query.scrollTo, null, 2500); // Adjust the duration here (in milliseconds)
          resolve();
        }, 300); // Delay to wait for the transition to finish
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

// Guard global pour mettre à jour le titre de l'onglet
router.beforeEach((to, from, next) => {
  const baseTitle = 'Nathalité Doula 02';
  const pageTitle = to.meta.title ? `${baseTitle} - ${to.meta.title}` : baseTitle;
  document.title = pageTitle;
  next();
});

// Hook afterEach pour gérer le défilement après le changement de route
router.afterEach((to, from) => {
  if (to.query.scrollTo) {
    nextTick(() => {
      const section = document.getElementById(to.query.scrollTo);
      if (section) {
        smoothScrollTo(section, 2500);
      }
    });
  }
});

export default router;