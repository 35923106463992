<template>
  <section id="contact" class="container box-shadow-1" itemscope itemtype="https://schema.org/ContactPage">
    <header>
      <div class="logo">
        <img class="img-logo" :src="$logo" alt="Logo de NatHalité Doula" itemprop="image" />
      </div>
      <h2 itemprop="name">Me contacter</h2>
    </header>

    <div class="contact-content">
        <ContactInfo />
        <ContactForm />
    </div>

  </section>
</template>

<script setup>

import { inject, useHead } from '@/js/import/importHead.js';
import ContactForm from '@/components/contact-path/ContactForm.vue';
import ContactInfo from '@/components/contact-path/ContactInfo.vue';

const $logo = inject('$logo');

useHead({
  title: 'Me contacter',
  meta: [
    { name: 'description', content: 'Me contacter' },
    { name: 'robots', content: 'index, follow' },
    { name: 'og:title', content: 'Me contacter' },
    { name: 'og:description', content: 'Me contacter' },
    { name: 'og:image', content: $logo },
    { name: 'og:url', content: 'https://nat-halite-doula.com/contact' },
    { name: 'og:type', content: 'website' },
    { name: 'og:locale', content: 'fr_FR' },
    { name: 'og:site_name', content: 'NatHalité Doula' },
    { name: 'og:image:width', content: '1200' },
    { name: 'og:image:height', content: '630' }
  ]
});

</script>

<style lang="scss" scoped>
@import '@/styles/contact.scss';

#contact {
  background-color: $bg-section-3;
  @include flex-column;
  gap: rem(48);
  margin-top: rem(183);

  .row {
    gap: rem(16);
    justify-content: space-between;
    align-items: center;
  }

  .row > div {
    flex: 1;
  }

  header {
    @include flex-column;
    gap: rem(50);
    @include flex-center;
    text-align: center;
  }

  .contact-content {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;

    .article, .contact-form {
      flex: 1;
    }
  }

  article {
    padding: 1rem;
    margin-bottom: 2rem;
    flex: 0 0 50%;

    h3 {
      text-align: start;
      @include font-size-responsive(0.8rem, 1.2rem);
;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5;
      margin: 0;
    }
  }
}

</style>