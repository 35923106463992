<template>
  <form @submit="handleSubmit" class="contact-form" novalidate itemprop="potentialAction" itemscope itemtype="https://schema.org/Action">
    <div class="row">
      <div class="form-group">
        <label for="name" itemprop="name">Nom</label>
        <input type="text" id="name" placeholder="Votre nom" name="name" v-model="name" itemprop="name">
      </div>
      <div class="form-group">
        <label for="firstName" itemprop="name">Prénom</label>
        <input type="text" id="firstName" placeholder="Votre prénom" name="firstName" v-model="firstName" required title="Veillez saisir votre Prénom" itemprop="name">
        <transition name="fade">
          <div class="bloc-error"  v-if="errors.firstName"><span class="error-message" itemprop="error">{{ errors.firstName }}</span></div>
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label for="phone" itemprop="name">Téléphone</label>
        <input type="tel" id="phone" placeholder="Votre téléphone" name="phone" v-model="phone" itemprop="name" >
        <transition name="fade">
          <div class="bloc-error" v-if="errors.phone"><span class="error-message" itemprop="error">{{ errors.phone }}</span></div>
        </transition>
      </div>
      <div class="form-group">
        <label for="email" itemprop="name">Email</label>
        <input type="email" id="email" placeholder="Votre email" name="email" v-model="email" required itemprop="name">
        <transition name="fade">
          <div class="bloc-error" v-if="errors.email"><span class="error-message" itemprop="error">{{ errors.email }}</span></div>
        </transition>
      </div>
    </div>
    <div class="row row-area">
      <div class="form-group-area">
        <label for="message">Message</label>
        <textarea id="message" placeholder="Votre message" name="message" v-model="message" required></textarea>
        <transition name="fade">
          <div class="bloc-error" v-if="errors.message"><span class="error-message" itemprop="error">{{ errors.message }}</span></div>
        </transition>
      </div>
    </div>
    <Button 
      class="submit-btn"  
      type="submit" 
      :colorClass="'bg-purple text-light'" 
      buttonClass="button" 
      :hoverColorClass="'hover-bg-nav hover-text-link'"
      buttonText="Envoyez"
    />

    <transition name="fade">
      <div class="response-message" v-if="responseMessage">{{ responseMessage }}</div>
    </transition>
  </form>
</template>

<script setup>
import { ref, watch, computed, useHead } from '@/js/import/importHead.js';
import axios from 'axios';
import Button from '@/views/Button.vue';

const name = ref('');
const firstName = ref('');
const phone = ref('');
const email = ref('');
const message = ref('');
const isSubmitting = ref(false);
const responseMessage = ref('');
const errors = ref({});

const validateForm = () => {
  errors.value = {};
  const requiredFields = { firstName, email, message };

  for (const [field, value] of Object.entries(requiredFields)) {
    if (!value.value.trim()) {
      if (field === 'firstName') {
        errors.value[field] = `Votre Prénom est requis.`;
        setTimeout(() => {
          errors.value[field] = '';
        }, 5000);
      }
      if (field === 'message') {
        setTimeout(() => {
          errors.value[field] = `Votre Message est requis.`;
        }, 600);
        setTimeout(() => {
          errors.value[field] = '';
        }, 5600);
      }
    }
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email.value)) {
    setTimeout(() => {
      errors.value.email = `L'adresse e-mail n'est pas valide.`;
    }, 300);
    setTimeout(() => {
      errors.value.email = '';
    }, 5300);
  }

  return Object.keys(errors.value).length === 0;
};

const resetForm = () => {
  name.value = '';
  firstName.value = '';
  phone.value = '';
  email.value = '';
  message.value = '';
};

const handleSubmit = async (event) => {
  event.preventDefault();
  if (!validateForm()) return;

  isSubmitting.value = true;
  responseMessage.value = '';

  try {
    const response = await axios.post('/api/send-email', {
      name: name.value,
      firstName: firstName.value,
      phone: phone.value,
      email: email.value,
      message: message.value,
    });
    responseMessage.value = response.data;
    resetForm();
  } catch (error) {
    responseMessage.value = 'Erreur lors de l\'envoi du message.';
  } finally {
    isSubmitting.value = false;
  }
};

// Watcher pour effacer le message après 10 secondes
watch(responseMessage, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      responseMessage.value = '';
    }, 10000); // 10 secondes
  }
});

// JSON-LD data
const jsonLd = computed(() => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Action",
    "name": "Envoyer un message",
    "target": {
      "@type": "EntryPoint",
      "urlTemplate": "https://votre-site-web.com/contact",
      "actionPlatform": [
        "http://schema.org/DesktopWebPlatform",
        "http://schema.org/MobileWebPlatform"
      ]
    },
    "result": {
      "@type": "Thing",
      "name": "Message envoyé"
    }
  });
});

// Utiliser useHead pour ajouter le script JSON-LD dans le head
useHead({
  script: [
    {
      type: 'application/ld+json',
      json: jsonLd.value
    }
  ]
});
</script>

<style lang="scss" scoped>
@import '@/styles/contact.scss';

.submit-btn{
  margin:auto;
}
</style>