<script setup>
import { ref, computed } from 'vue';
import { useHead } from '@vueuse/head';
import { photo_profil, doula_accueil } from '@/js/import/images/home/homeImages.js';

const title = ref('NatHalité<br>Doula 02');
const description = ref('Accompagnante naissance, sécurisante, bien-être, écoute active et bienveillance - NatHalité Doula 02');

const jsonLd = computed(() => ({
  "@context": "https://schema.org",
  "@type": "Person",
  "name": "NatHalité Doula 02",
  "jobTitle": "Doula",
  "description": description.value,
  "image": photo_profil,
  "url": "https://votresite.com", // Remplacez par l'URL réelle de votre site
  "sameAs": [
    "https://www.facebook.com/votrepagefacebook", // Remplacez par vos liens réels
    "https://www.instagram.com/votrecompteinstagram"
  ],
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "Saint-Quentin",
    "addressRegion": "Aisne",
    "addressCountry": "France"
  },
  "telephone": "+33123456789",
  "email": "contact@nathalitedoula02.com"
}));

const jsonLdString = computed(() => JSON.stringify(jsonLd.value));

useHead({
  title: 'NatHalité Doula 02 - Accompagnante naissance',
  meta: [
    { name: 'description', content: description.value },
    { name: 'keywords', content: 'doula, accompagnante naissance, bien-être, écoute active, bienveillance' },
    { property: 'og:title', content: 'NatHalité Doula 02 - Accompagnante naissance' },
    { property: 'og:description', content: description.value },
    { property: 'og:image', content: photo_profil },
    { property: 'og:type', content: 'website' },
    { 'http-equiv': 'content-language', content: 'fr' },
  ],
  link: [
    { rel: 'canonical', href: 'https://votresite.com' } // Remplacez par l'URL réelle de votre site
  ]
});
</script>

<template>
  <!-- header section -->
  <header id="header" itemscope itemtype="https://schema.org/WPHeader">
    <div>
      <h1 itemprop="headline" v-html="title"></h1>
      <img class="responsive-img" :src="photo_profil" alt="Photo de profil NatHalité Doula 02" itemprop="image" /> 
    </div>
    <!-- introduction -->
    <section itemprop="description">
      <ul class="intro-list">
        <li :class="'text-red'" itemprop="knowsAbout"><strong>Accompagnante naissance</strong></li>
        <li :class="'text-purple'" itemprop="knowsAbout"><strong>Sécurisante</strong></li>
        <li :class="'text-link'" itemprop="knowsAbout"><strong>Bien-être</strong></li>
        <li :class="'text-purple'" itemprop="knowsAbout"><strong>Écoute active</strong></li>
        <li :class="'text-red'" itemprop="knowsAbout"><strong>Bienveillance</strong></li>
      </ul>
    </section>
    <!-- profile photo -->
    <div>
      <img class="responsive-img" :src="doula_accueil" alt="Photo illustrative NatHalité Doula 02" itemprop="image" /> 
    </div>
    <div v-html="`<script type='application/ld+json'>${jsonLdString}</script>`"></div>
  </header>
</template>

<style lang="scss" scoped>

#header {
        padding: 1rem 5vw;
        @include flex-row;
        justify-content: space-between;
        align-items: center;
        background-color: $bg-section-1;

       @include media-breakpoint-down(md) {
          flex-direction: column;
          justify-content: center;
          gap: rem(32);
        }

        section {
          
          .intro-list{
            @include flex-column;
            padding: 0;
            gap: rem(32);
            color: $purple;
            font-style: italic;
            @include font-size-responsive(rem(24), rem(32));
            text-align: center;
          }

            .intro-list li {
              @include li-list-none;
            }
            
        }

        @include media-breakpoint-down(lg)  {
          :nth-last-child(1) {
          margin: auto;
        }
        }
      }

</style>