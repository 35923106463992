<script setup>
import { ref } from 'vue'
import InfinitSlider from '@/components/home-path/InfinitSlider.vue';
const title = ref('Avantage d\'une doula')
</script>

<template>

<!-- doula-about section -->
  <section id="doula-about" class="container box-shadow-1">
    <header>
      <h2>{{ title }}</h2>
      <h3>Se faire accompagner d'une doula offre de nombreux avantages :</h3>
    </header>
    <ol class="service-content__list">
      <li><strong>Soutien émotionnel et physique</strong> : La doula est présente pour écouter et apaiser les craintes, réduisant le stress et l'anxiété.</li>
      <li><strong>Information et préparation </strong>: Elle fournit des informations essentielles sur la grossesse, l'accouchement et les soins postnataux.</li>
      <li><strong>Accompagnement personnalisé </strong>: La doula aide à élaborer un plan de naissance adapté aux besoins et souhaits des parents.</li>
      <li><strong>Assistance continue :</strong> Elle est disponible tout au long de la grossesse, pendant l'accouchement et après la naissance, assurant une présence constante et rassurante.</li>
      <li><strong>Amélioration des expériences de naissance</strong> : Les recherches montrent que la présence d'une doula peut contribuer à des accouchements plus courts et à une réduction des interventions médicales.</li>
    </ol>
    <InfinitSlider />
  </section>
</template>

<style lang="scss" scoped>

#doula-about {
    background-color: $bg-section-2;
    padding: $padding-size;
    @include flex-column;

    h4{
      text-align: left;
      padding: rem(16);
    }
    
    div {
      h3 {
        padding: rem(16);
      }
    }

    section {
      @include flex-column;
      gap: rem(16);

      ol {
        padding-left: rem(16);


        li {
          list-style: decimal;

          strong {
            font-weight: bold;
            color: $red-color;
          }
        }
      }
    }
  }

</style>