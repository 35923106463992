// src/utils/scrollUtils.js
import { nextTick } from 'vue';

export function smoothScrollTo(target, maxDuration = 2500) {
  const targetPosition = target === 0 ? 0 : target.getBoundingClientRect().top + window.pageYOffset;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  const speed = Math.abs(distance) / maxDuration; // Calculer la vitesse pour que la durée soit au maximum maxDuration
  let duration = Math.abs(distance) / speed;

  // Limiter la durée à maxDuration
  if (duration > maxDuration) {
    duration = maxDuration;
  }

  let startTime = null;

  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);

    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    } else {
      window.scrollTo(0, targetPosition);
    }
  }

  requestAnimationFrame(animation);
}

export function scrollToSection(sectionId, router, maxDuration = 2500) {
  nextTick(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      smoothScrollTo(section, maxDuration);
    } else {
      smoothScrollTo(0, maxDuration);
    }
  });
}

export function handleInitialScroll(route) {
  if (route.query.scrollTo && !sessionStorage.getItem('scrollPerformed')) {
    scrollToSection(route.query.scrollTo, null, 2500);
    sessionStorage.setItem('scrollPerformed', 'true');
  }
}

export function scrollToTop(maxDuration = 2500) {
  smoothScrollTo(0, maxDuration);
}