<template>
  <div class="contact-info" itemscope itemtype="https://schema.org/Organization">
    <section>
      <div>
        <h3 itemprop="name">Nathalite Vieville</h3>
        <strong itemprop="jobTitle">Doula</strong> <strong>dans</strong> <strong itemprop="areaServed">l'Aisne</strong>
      </div>
      <div>
        <p itemprop="email">
          <i class="fa-solid fa-envelope"></i>
          nathalie.vieville@hotmail.com</p>
        <p itemprop="telephone">
          <i class="fa-solid fa-phone"></i> 
          07 78 05 33 54</p>
      </div>
      <div>
        <h3 itemprop="service">Séance à domicile</h3>
        <p itemprop="areaServed">Déplacement dans les Hauts de France jusqu'à 60km à partir de Moÿ de l'Aisne</p>
      </div>
      <div>
        <p itemprop="description">Souhaitez-vous en discuter par téléphone ?</p>
      </div>
    </section>
    <section>
      <iframe target="_blank" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12261.369317478222!2d3.3597247427644303!3d49.755304124235685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e83da88d0803a9%3A0x5ef25c6c0520bb71!2sNatHalit%C3%A9%20Doula02!5e0!3m2!1sfr!2sfr!4v1722198370744!5m2!1sfr!2sfr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>

@import '@/styles/contact.scss';



.contact-info , .contact-form {
      flex: 1;
      @include flex-column;
      gap: rem(20);
      @include flex-center;
    }


.contact-info {
  margin-bottom: rem(20);
}

h3 {
  margin: rem(10) 0;
}

section  {
  display: flex;
  flex-direction: column;
  gap: rem(15);
}

iframe {
  width: 25vw;
  height: 25vw;
}

@media (max-width: 768px) {
  iframe {
    width: 40vw;
    height: 40vw;
  }
}
</style>