<script setup>
import { ref } from 'vue'
import { inject } from 'vue';
import Button from '@/views/Button.vue';
import { doula_famille_papier } from '@/js/import/images/home/homeImages'; // Importez l'image depuis homeImages.js

const welcomeText = ref('Bonjour et bienvenue sur le site de <strong itemprop="name">NatHalité Doula 02</strong>');
</script>

<template>
      <!-- welcome section -->
      <section id="welcome" class="container box-shadow-1 parallax" itemscope itemtype="https://schema.org/WebPageElement">
        <div class="welcome-text-bloc bg-section-3-opacity">
        <div>
          <h2 v-html="welcomeText"></h2>
        </div>
        <p :class="'text-dark'">Je m'appelle <strong :class="'text-dark'">Nathalie Vieville</strong>, <strong :class="'text-dark'">maman d'un garçon né en 2011</strong>. Depuis mon plus jeune âge,
          je suis passionnée par le domaine du bien-être et convaincue qu'il est essentiel d'accompagner
            les <strong :class="'text-dark'">familles</strong> pour les aider au mieux. C'est dans cette optique que j'ai créé <strong itemprop="name">NatHalité Doula02</strong> 
            le 1er avril 2024. Mon entreprise se consacre à offrir un <strong :class="'text-dark'">soutien complet</strong> aux familles, en 
            répondant à leurs besoins physiques, émotionnels et logistiques à chaque étape de la vie. 
            De la <strong :class="'text-dark'">préconception</strong> à l'accouchement, en passant par les périodes <strong :class="'text-dark'">prénatales</strong> et <strong :class="'text-dark'">postnatales</strong>, 
            ainsi que les moments délicats comme le <strong :class="'text-dark'">deuil périnatal</strong> ou la <strong :class="'text-dark'">ménopause</strong>, 
            je suis là pour vous <strong :class="'text-dark'">accompagner</strong> avec <strong :class="'text-dark'">bienveillance</strong> et <strong :class="'text-dark'">expertise</strong>.</p>
            <footer class="btn-block">
            <p :class="'text-purple'">Vous avez des questions ? Contactez moi !</p>
              <Button 
                :colorClass="'bg-red text-light'" 
                buttonClass="button" 
                :hoverColorClass="'hover-bg-nav hover-text-link'" 
                :isScrollButton="true"
                buttonText="Me contacter"
                sizeClass="size-medium"
                paddingClass="padding-medium"
                marginClass="margin-medium"
                scrollToSection="contact"
              />
            </footer> 
        </div>
      </section>
</template>

<style lang="scss" scoped>
  /* welcome */

  .parallax {
    background-image: url('@/assets/service-img/famille-image-papier.jpg'); // Utilisez l'importation de l'image
  }

  #welcome {
    

    
    .welcome-text-bloc{
      border-radius: 10px;
      padding: 2rem;
      gap: 2rem;
      @include flex-column;
      @include flex-center;


    }

    p {
      padding: 1rem;
      border-radius: 30px;

    font-family: 'Happy Monkey';
    @include font-size-responsive(dynamic-padding(1.2), dynamic-padding(1.5));
    }

        .btn-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: dynamic-padding(1);
    }

    .btn-contact {
      @include font-size-responsive(dynamic-padding(1.0), dynamic-padding(1.0));
    }
}

</style>

