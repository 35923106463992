<template>
  <transition
    name="modal"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <div v-if="isOpen" class="modal" @click="close">
      <div class="image-container" @click.stop>
        <div class="close-container" :class="{ 'visible': isCloseVisible }">
          <span class="close purple" @click="handleClose">&times;</span>
        </div>
        <img class="modal-content" :src="imageSrc" alt="Image en grand format" @click.stop>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  isOpen: Boolean,
  imageSrc: String,
});

const emit = defineEmits(['close']);
const isCloseVisible = ref(false);

function close() {
  emit('close');
}

function handleClose() {
  close();
  isCloseVisible.value = false;
}

// Hooks de transition pour gérer l'opacité
function beforeEnter(el) {
  el.classList.add('modal-enter');
}

function enter(el, done) {
  requestAnimationFrame(() => {
    el.classList.add('modal-enter-active');
    el.classList.remove('modal-enter');
    done();
  });
}

function afterEnter(el) {
  el.classList.remove('modal-enter-active');
  el.classList.add('modal-enter-to');
  isCloseVisible.value = true;
}

function leave(el, done) {
  el.classList.add('modal-leave-active');
  setTimeout(() => {
    el.classList.remove('modal-leave-active');
    done();
  }, 800); // Assure-toi que cela correspond à la durée de la transition
}
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.8s ease;
}

.modal-enter-active, .modal-enter-to, .modal-leave-active {
  opacity: 1;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
}

.image-container {
  position: relative;
  max-width: 55%;
  height: auto;
  margin: auto;
}

.modal-content {
  max-width: 100%;
  height: 100%;
  max-height: rem(580);
  transition: transform 0.8s ease;
}

.modal-enter-active .modal-content, .modal-leave-active .modal-content {
  transform: scale(1);
}

.modal-enter .modal-content, .modal-leave-to .modal-content {
  transform: scale(0.9);
}

.close-container {
  position: absolute;
  top: 0;
  right: 5px;
  background-color: $bg-section-3-opacity;
  border-radius: 50%;
  height: rem(30);
  width: rem(30);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: rem(24) rem(36);
  opacity: 0;
  transition: background-color 0.3s ease, opacity 0.3s ease;

  &:hover {
    background-color: $bg-section-3;
  }

  @media (max-width: 768px) {
    margin: 1rem;
    width: 20px;
    height: 20px;
  }
}

.close-container.visible {
  opacity: 1;
}

.close {
  position: relative;
  color: $red-color;
  font-weight: bold;
  cursor: pointer;
  place-items: center;
  @include font-size-responsive(rem(20), rem(30));
}
</style>