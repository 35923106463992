<script setup>
import { ref } from 'vue';
import Modal from '@/views/Modal.vue';
import doulaFrance from '@/assets/parcours-img/LOGO-DDF-RVB.png';
import mamanBlue from '@/assets/parcours-img/Maman-Blues-Logo-nov-2019.png';
import petiteEmilie from '@/assets/parcours-img/Petite-emilie-logo.jpg';
import { useModal } from '@/js/use/useModal';
const { isModalOpen, modalImage, openModal, closeModal } = useModal();
const sectionTitle = ref('Association');
const subTitle1 = ref('Doula de France');
const subTitle2 = ref('Objet de l\'association');
const subTitle3 = ref('Mode de fonctionnement');
const subTitle4 = ref('Autres associations');
</script>

<template>
  <!-- Association section -->

  <section id="association" class="container box-shadow-1" itemprop="Section" itemscope itemtype="https://schema.org/WebPageElement">

    <article class="content-section1" itemscope itemtype="https://schema.org/Article" itemprop="articleBody">
        <div><h2 itemprop="headline">{{ sectionTitle }}</h2></div>

        <div class="container bg-bloc-text" itemprop="articleSection">

          <div class="row justify-center align-center">
            <div class="logo">
              <img class="responsive-img img-logo " :src="doulaFrance" alt="Logo de l'école de formation" itemprop="image" />
            </div>
          </div>

            <div><h3 itemprop="headline">{{ subTitle1 }}</h3></div>

            <div><h4 itemprop="headline">{{ subTitle2 }}</h4></div>


        <p itemprop="text">
            Développer et promouvoir en France le travail des <strong itemprop="name">doulas</strong> et l’accompagnement 
            dans la <strong itemprop="name">période périnatale</strong>, et favoriser l’humanisation  de la naissance aux 
            côtés des <strong itemprop="name">parents</strong>, des <strong itemprop="name">sages-femmes</strong>, des <strong itemprop="name">institutions</strong>, des <strong itemprop="name">associations</strong>.
        </p>
        <p itemprop="text">
        C’est une association professionnelle.
        </p>
        <p itemprop="text">L’association a pour objectifs :</p>
        <ul class="list disc-list container" itemprop="about">
        <li>Soutenir et informer les femmes, les parents dans l’exercice de leur parentalité 
            et dans le respect de leurs choix et de leur culture favoriser la relation mère-enfant</li>
        <li>Sensibiliser au respect des besoins fondamentaux de l’enfant et de la femme</li>
        <li>Encourager les processus physiologiques et notamment l’allaitement maternel</li>
        <li>Prévenir la difficulté maternelle, la dépression post-natale, la maltraitance, et leurs impacts sur les familles</li>
        <li>Soutenir et créer des réseaux dans le domaine de l’accompagnement à la naissance</li>
        <li>Étendre la transmission de femme à femme</li>
        <li>Offrir une voie de réinsertion professionnelle</li>
        </ul>

        <section class="container">

          <h4>{{ subTitle3 }}</h4>
        <p><a href="https://doulas.info" target="_blank">“Doulas de France”</a> est une association de loi 1901 à but non lucratif, 
        dont les statuts ont été déposés à la préfecture de Bobigny le 14 février 2006.
        </p>
        <strong>
            Doulas de France est une association indépendante, qui fonctionne grâce aux adhésions de ses membres et au travail des volontaires. Elle n’emploie aucun salarié, et tous les membres du bureau, du conseil d’administration, les membres actifs sont bénévoles.
        </strong>
        <p>L’association n’est pas à l’origine du mot “doula” qui est un terme utilisé dans le monde entier et reconnu dans le domaine public international. Elle ne se porte pas garante des personnes utilisant ce terme en France
        </p>

        </section>

        <cite itemprop="name" class="text-center">
        <a href="https://doulas.info" target="_blank">“Doulas de France” : https://doulas.info</a>
        </cite>
    </div>
    </article>

    <section class="content-section">
        <h3>{{ subTitle4 }}</h3>

        <div class="row justify-center align-center">
        <div class="logo assoc-logo">
            <figure>
            <img class="responsive-img img-logo radius-5-p" :src="mamanBlue" alt="Logo Maman Blues" itemprop="logo" />
                <figcaption><a href="https://www.maman-blues.fr" target="_blank" itemprop="url">Maman Blues</a></figcaption>
            </figure>
        </div>
        <div class="logo assoc-logo">
            <figure>
            <img class="responsive-img img-logo" :src="petiteEmilie" alt="Logo Petite Emilie" itemprop="logo" />
                <figcaption><a href="https://www.petite-emilie.com" target="_blank" itemprop="url">Petite Emilie</a></figcaption>
            </figure>
        </div>
        </div>
    </section>

    <!-- Modale pour afficher l'image en grand format -->
    <Modal :isOpen="isModalOpen" :imageSrc="modalImage" @close="closeModal" />

</section>

</template>


     

<style lang="scss" scoped>

@import '@/styles/list.scss';



/* parcours */

#association {
    background-color: $bg-section-3;

    a {
        text-decoration: underline;
    }

}



  .bg-bloc-text {
        background-color: $background-color-body;
        margin-top: auto;
        border-radius: 20%;
        margin-top: rem(32);
      }

      p {
        align-self: center;

      }

  .parcours-section {
    width: 100%;


  }

  .parcours-content {
    align-self: stretch;
    @include flex-row;
    @include flex-center;

    width: 100%; /* Garantit que le conteneur occupe toute la largeur */
    gap: rem(32); /* Espace entre les éléments */
    


    .block-img {
      margin: auto;

      img {
        width: 100%;
        max-width: none;
      }
    }
  }

.big-img {
  width: 100%;
  max-width: 500px;
  cursor: pointer;
  transition: transform 0.8s ease;
}
.big-img:hover {
  transform: scale(1.1);
}

.assoc-logo {
      width: 150px;
      height: 150px;
      @include flex-center;
      



      figure {
        @include flex-center;
        @include flex-column;

        figcaption {
            text-align: center;
            margin-top: auto;
            font-size: font-size-responsive(rem(10), rem(16));
            margin-top: rem(10);
        }

        .responsive-img {
        width: auto;
        height: 150px;
      }

      }


    }





</style>