import { ref } from 'vue';

export function useModal() {
  const isModalOpen = ref(false);
  const modalImage = ref('');

  function openModal(imageSrc) {
    modalImage.value = imageSrc;
    isModalOpen.value = true;
  }

  function closeModal() {
    isModalOpen.value = false;
    modalImage.value = '';
  }

  return {
    isModalOpen,
    modalImage,
    openModal,
    closeModal,
  };
}