<script setup>
import { onMounted, onUnmounted, useHead, ref } from '@/js/import/importHead.js';
import { useRouter, useRoute } from 'vue-router';
import { scrollToSection } from '@/js/use/scrollUtils';
import { inject } from 'vue';
import ScrollToTopButton from '../views/ScrollToTopButton.vue';
import Button from '@/views/Button.vue';
import Header from '@/components/home-path/header.vue';
import Welcome from '@/components/home-path/welcome.vue';
import DoulaQuote from '@/components/home-path/doula_quote.vue';
import DoulaStory from '@/components/home-path/histoire_doula.vue';
import DoulaAbout from '@/components/home-path/doula_about.vue';
import Parcours from '@/components/home-path/parcours.vue';
import Association from '@/components/home-path/Association.vue';

const $logo = inject('$logo');
const router = useRouter();
const route = useRoute();

const title = ref('Nathalité Doula 02 - Dans l\'Aisne');

onMounted(() => {
  if (route.query.scrollTo) {
    scrollToSection(route.query.scrollTo, 3500);
  }
});



useHead({
  title: title.value,
  meta: [
    { name: 'description', content: 'Découvrez le désir de grossesse avec NatHalité Doula. Un soutien émotionnel et pratique pour votre parcours vers la conception.' },
    { name: 'keywords', content: 'Grossesse, Doula, NatHalité Doula, Soutien émotionnel, Fertilité, Groupe de parole, Femme enceinte, enfant, enfants, familles, Saint-Quentin, Laon, Soissons, Aisne, Jumeaux, Couple' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
  ],
  script: [
    {
      type: 'application/ld+json',
      json: {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "NatHalité Doula 02",
        "url": "https://votre-site-web.com",
        "logo": "https://votre-site-web.com/path-to-logo.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+33-1-23-45-67-89",
          "contactType": "Customer Service"
        }
      }
    },
    { src: 'https://schema.org/WebPageElement', type: 'application/ld+json' },
    { src: 'https://schema.org/WPHeader', type: 'application/ld+json' },
    { src: 'https://schema.org/Quote', type: 'application/ld+json' },
    { src: 'https://schema.org/ItemList', type: 'application/ld+json' }
  ]
});

</script>



<template>
  <div>
    <!-- Home section -->
    <main id="home" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
      <Header itemprop="header"></Header>

      <!-- welcome section -->
      <Welcome itemprop="mainEntity"></Welcome>

      <!-- parcours section -->
      <Parcours></Parcours>

      <!-- association section -->
      <Association itemprop="mainEntity"></Association>
      
      <!-- doula-quote section -->
      <DoulaQuote itemprop="mainEntity"></DoulaQuote>

      <!-- doula-about section -->
      <DoulaAbout itemprop="mainEntity"></DoulaAbout>

      <!-- doula-story section -->
      <DoulaStory itemprop="mainEntity"></DoulaStory>
    </main>

    <!-- Scroll to Top button -->
    <div>
      <ScrollToTopButton/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    ScrollToTopButton,
    Button
  },
};
</script>

<style lang="scss" scoped>
</style>