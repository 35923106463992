<template>
  <router-link v-if="to" :to="to" custom>
    <template v-slot="{ navigate }">
      <button 
        :class="[buttonClass, { 'is-scroll-button': isScrollButton }, colorClass, sizeClass, paddingClass, marginClass]" 
        @mouseover="addHoverClass" 
        @mouseleave="removeHoverClass" 
        @click="handleClick(navigate, $event)"
      >
        {{ buttonText }}
      </button>
    </template>
  </router-link>
  <button 
    v-else 
    :class="[buttonClass, { 'is-scroll-button': isScrollButton }, colorClass, sizeClass, paddingClass, marginClass]" 
    @mouseover="addHoverClass" 
    @mouseleave="removeHoverClass" 
    @click="handleClick(null, $event)"
  >
    {{ buttonText }}
  </button>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { scrollToSection } from '@/js/use/scrollUtils';

const props = defineProps({
  type: {
    type: String,
    default: 'button'
  },
  buttonClass: {
    type: String,
    default: 'submit-btn'
  },
  isScrollButton: {
    type: Boolean,
    default: false
  },
  to: {
    type: String,
    default: null
  },
  colorClass: {
    type: String,
    default: ''
  },
  hoverColorClass: {
    type: String,
    default: ''
  },
  buttonText: {
    type: String,
    default: 'Button Text'
  },
  sizeClass: {
    type: String,
    default: ''
  },
  paddingClass: {
    type: String,
    default: ''
  },
  marginClass: {
    type: String,
    default: ''
  },
  scrollToSection: {
    type: String,
    default: null
  }
});

const emit = defineEmits(['click']);
const router = useRouter();

function handleClick(navigate, event) {
  const target = event.target;
  if (target.tagName === 'A' || target.closest('a')) {
    return; // Laisse le lien gérer le clic
  }

  if (props.isScrollButton && props.scrollToSection) {
    event.preventDefault();
    if (props.to) {
      router.push(props.to).then(() => {
        scrollToSection(props.scrollToSection, router);
      });
    } else {
      scrollToSection(props.scrollToSection, router);
    }
  } else if (props.to && navigate) {
    navigate(event);
  } else {
    emit('click', event);
  }
}

function addHoverClass(event) {
  if (props.hoverColorClass) {
    event.target.classList.add(...props.hoverColorClass.split(' '));
  }
}

function removeHoverClass(event) {
  if (props.hoverColorClass) {
    event.target.classList.remove(...props.hoverColorClass.split(' '));
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';

/* btn-block */

.btn-block {
  display: flex;
  width: rem(247);
  height: rem(50);
  padding: rem(0) rem(5) rem(1) rem(4);
  @include flex-center;
  text-wrap: nowrap; 
  font-weight: 700;

  .button, .submit-btn {
    display: inline-block;
    border: none;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 700;
    font-family: $font-family;
    text-decoration: none;
    text-wrap: nowrap;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;

    &:active {
      margin-top: rem(10);
    }

    &:hover {
      background-color: $bg-nav;
      transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    }

    &:focus,
    &:focus-visible {
      outline: 4px auto -webkit-focus-ring-color;
    }
  }
}

/* submit-btn */

.submit-btn {
  display: inline-block;
  width: rem(247);
  height: rem(50);
  padding: rem(10) rem(30);
  border: none;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: $font-family;
  background-color: transparent;
  box-shadow: 0 0 10px 0 $purple; // Ajoutez cet effet de box-shadow
  @include font-size-responsive(0.7rem, 1rem);

  &:span {
    background-color: transparent;
    transition: all 0.3s ease;
  }

  &:hover {
    background-color: white;
  }
}
</style>