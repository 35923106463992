<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { GroupButton } from '@/js/import/importView.js';

const title = ref('Soin d\'ouverture et de fermeture')

useHead({
  title: title.value,
  meta: [
    { name: 'description', content: 'Le soin d\'ouverture est une pratique traditionnelle mexicaine qui est souvent utilisée pendant et après la grossesse, bien que ses applications puissent aller au-delà de cette période spécifique de la vie d\'une femme.' },
    { name: 'keywords', content: 'soin d\'ouverture, doula, natation, grossesse, accouchement, bébé, guérison, guérisseur, sages-femme, mexique, doula, natation, grossesse, accouchement, bébé, guérison, guérisseur, sages-femme, mexique' }
  ]
});


</script>

<template>
  <article class="service-content" itemscope itemtype="https://schema.org/Article" itemprop="articleBody">
    <!-- Balises meta SEO -->
    <header class="container box-shadow-1 header" itemprop="headline">
      <h1>{{ title }}</h1>
    </header>

    <section class="container" itemprop="articleSection">

      <div class="logo">
          <img class="img-logo" src="@/assets/logo_nathalite_doula.png" alt="Logo de NatHalité Doula" itemprop="image"/>
        </div>

      <div class="service-content__block" >
        <h2 >Soin d'ouverture <br> (Pendant la grossesse)</h2>
      </div>
      <div class="service-content__block" >
        <ul class="list">
            <li><strong>Dès le 4ème mois de grossesse</strong></li>
            <li><strong>Action : </strong>Bercement, étirement, enveloppement doux (Jambes pieds, bassins, épaules, tête), massage des pieds </li>
            <li><strong>Les bienfaits :</strong> Détente profondes, soulagement des tentions, ouverture du bassin</li>
            <li><strong>Connexion avec le bébé</strong></li>
            <li><strong>Lieu :</strong> À domocile</li>
            <li><strong>Durée de l'action : </strong> 2h rien que pour vous</li>
          </ul>
      </div>
    </section>

    <section class="container" itemprop="articleSection">
      <div class="service-content__block">
        <h2>Le resserrage de bassin <br> en post-partum(ou pas)</h2>
      </div>

      <div class="service-content__block" >
        <ul class="list">
            <li><strong>En postnatal immédiat :</strong> sauf en cas de césarienne et sans limite dans le  temps</li>
            <li><strong>Action : </strong>Bercement, étirement, enveloppement du bassin à l'aide d'un bâton de serrage</li>
            <li><strong>Les bienfaits :</strong> Soulagement des ligaments, sensation de centrage, de retour à soi, d'ancrage</li>
            <li><strong>Apporte aussi :</strong> Support aux organes internes. Symbolique de fermeture du passage</li>
            <li><strong>Lieu :</strong> À domocile</li>
            <li><strong>Durée de l'action : </strong> 45 min rien que pour vous</li>
          </ul>
      </div>

      <div class="service-content__block">
        <h3>Soins de fermeture en post-partum(ou pas)</h3>
      </div>

      <div class="service-content__block">
        <ul class="list">
            <li>
              <strong>A tout moment de la vie</strong> d'une femme (très adapté au post-partum)
            </li>
            <li><strong>Action :</strong> Bercement, étirement, enveloppement doux (Jambes pieds, bassins, épaules, tête), points 
              dont le bassin, massage des pieds 
            </li>  
            <li><strong>Bienfaits :</strong> Détente profonde, soulagement des tensions, sensation d'alignement, d'ancrage.
            </li>
            <li><strong>Mais également</strong> Reconnexion à son corps et à sa fémininité. Symbole de passage.
            </li>
            <li><strong>Lieu :</strong> À domocile</li>
            <li><strong>Durée de l'action : </strong> 2h rien que pour vous</li>
          </ul>
      </div>
    </section>
    <div class="service-end">
      <div class="row justify-center">
        <GroupButton />
      </div>
    </div>
  </article>
</template>

<script>


export default {
name: 'Bain rituel',
}

function handleContactClick() {
  handleClick('contact', router);
}
</script>

<style lang="scss" scoped>

@import '../../styles/services_item.scss';


</style>

