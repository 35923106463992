<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { GroupButton } from '@/js/import/importView.js';

const title = ref('Désire d\'enfant')

useHead({
  title: title.value,
  meta: [
    { name: 'title', content: 'Nahtalité Doula 02 - Désire d\'enfant' },
    { name: 'description', content: 'Découvrez le désir de grossesse avec NatHalité Doula. Un soutien émotionnel et pratique pour votre parcours vers la conception.' },
    { name: 'keywords', content: 'désir de grossesse, conception, NatHalité Doula, soutien émotionnel, fertilité' },
  ],
});
</script>

<template>
  <article  class="service-content" itemscope itemtype="https://schema.org/Article" itemprop="articleBody">
    <header  class="container box-shadow-1 header" itemprop="headline">   
      <h1 itemprop="name">{{ title }}</h1>
    </header>


    <section class="container" itemprop="articleSection">
      <div class="logo">
        <img class="img-logo" src="@/assets/logo_nathalite_doula.png" alt="Logo de NatHalité Doula" itemprop="image"/>
      </div>

      <div class="service-content__block">
        <p>
          Le <strong>désir de grossesse</strong>  est un sentiment profond et complexe ressenti par de nombreuses personnes 
          à un moment de leur vie. Ce désir peut être motivé par divers facteurs, tels que l'aspiration à <strong>une 
          famille</strong>, l'envie de donner et de recevoir de l'<strong>amour inconditionnel</strong>, ou encore 
          le souhait de transmettre ses <strong>valeurs</strong> et son <strong>héritage</strong>.
        </p>
        <p>
          Pour ceux qui ressentent ce désir, le chemin vers la <strong>grossesse</strong> peut varier considérablement. 
          Certaines personnes<strong>conçoivent</strong> facilement, tandis que d'autres peuvent rencontrer des obstacles. 
          Les défis peuvent inclure des problèmes de <strong>fertilité</strong>, des conditions médicales préexistantes, 
          ou même des facteurs liés au <strong>mode de vie</strong> et au <strong>stress</strong>.
        </p>
        <p>
          Le soutien d'une <strong>doula</strong> peut être extrêmement bénéfique tout au long de ce parcours. 
          Une <strong>doula</strong> offre un <strong>soutien émotionnel</strong> en étant une 
          <strong>présence constante et rassurante</strong>. Elle peut aider à <strong>gérer les sentiments de frustration</strong>, 
          d'<strong>anxiété</strong> et de <<strong>déception</strong> qui peuvent surgir lorsqu'une <strong>grossesse</strong>
          tarde à se réaliser. Par des <strong>discussions ouvertes</strong> et sans jugement, 
          la <strong>doula</strong> aide à <strong>exprimer</strong> et à <strong>traiter</strong> ces émotions.
        </p>
        <p>
          En outre, une <strong>doula</strong> fournit une <strong>éducation précieuse</strong> sur la <strong>fertilité</strong> 
          et la <strong>conception</strong>. Elle peut expliquer le <strong>cycle menstruel</strong>, identifier les périodes de
          <strong>fertilité optimales</strong>et donner des <strong>conseils</strong> sur les pratiques qui peuvent augmenter 
          les <strong>chances de conception</strong>. Si des <strong>traitements de fertilité</strong> sont nécessaires, 
          la <strong>doula</strong> guide le <strong>couple</strong> à travers les différentes options, 
          les aidant à comprendre chaque étape et à prendre des décisions éclairées.
        </p>
          <p>La présence d'une <strong>doula</strong> lors des rendez-vous médicaux et des traitements peut également être 
          rassurante. Elle offre un <strong>soutien pratique et émotionnel</strong>, aidant à naviguer dans les complexités 
          des <strong>soins de fertilité</strong>.
        </p>
        <p>
          Enfin, une <strong>doula</strong> aide à créer un <strong>réseau de soutien</strong>, en orientant vers des 
          spécialistes, des <strong>groupes de soutien</strong>, et d'autres ressources utiles. Elle peut aussi mettre 
          en relation avec des <strong>parents</strong> ayant vécu des expériences similaires, 
          offrant un sentiment de <strong>communauté</strong> et de <strong>compréhension</strong>.
        </p>
        <p>
          En résumé, le <strong>désir de grossesse</strong> est une quête personnelle et émotionnelle, 
          et <strong>le soutien d'une doula</strong> peut rendre ce parcours plus <strong>serein</strong> et <strong>positif</strong>, 
          en offrant une combinaison de soutien émotionnel, d'information et de connexion communautaire.</p>
        <p>
          Le <strong>désir de grossesse</strong> est une quête personnelle et émotionnelle, 
          et <strong>le soutien d'une doula</strong> peut rendre ce parcours plus <strong>serein</strong> et <strong>positif</strong>, 
          en offrant une combinaison de soutien émotionnel, d'information et de connexion communautaire.
        </p>
      </div>
    </section>

    <div class="service-end">
        <div class="row justify-center">
          <GroupButton />
        </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import '../../styles/services_item.scss';

</style>