<script setup>
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';
import { inject, watch, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { nextTick } from 'vue';
const route = useRoute();



const router = useRouter();

const isNotFoundPage = computed(() => route.name === 'NotFound');


</script>
<template>
  <div class="page-container">
    <NavBar v-if="!isNotFoundPage"/>
    <div class="main-content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <Footer v-if="!isNotFoundPage"/>
  </div>
</template>
<style lang="scss" scoped>
</style>
