<script setup>
import { ref } from 'vue'
import { inject } from 'vue';
const $logo = inject('$logo');
</script>
<template>
  <footer class="footer-content box-shadow-1" itemscope itemtype="https://schema.org/WPFooter">
    <div>
      <router-link to="/confidentiality-policy" itemprop="url">Mention légal & Politique de confidentialité</router-link>
    </div>
    <div class="footer" itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
      <p itemprop="name">Nathalite Vieville</p>
      <p itemprop="addressLocality">Moÿ de l'aisne, Aisne</p>
      <p itemprop="streetAddress">Domaine Saint-Quentinois</p>
    </div>
    <div class="logo" itemprop="logo"> 
      <img class="img-logo" :src="$logo" alt="logo" />
      <p>© 2024 Tous droits réservés</p>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
.footer-content {
  margin-top: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $bg-nav;   
  padding: $padding-size;

@media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }
}

.logo {
  @include flex-column;
  align-items: center;
  font-size: 0.6rem;
  gap: 0.5rem;
}

.img-logo {
  width: 80px;
  height: 80px;
}


.footer {
  @include flex-column;
  gap: 1rem;
  align-items: center;

  
@media (max-width: 768px) {
      flex-direction: row;
    }
}

.footer p {
  font-size: 0.8rem;
  color: $purple;
}

.footer p:first-child {
  font-style: italic;
  font-size: 1.0rem;
}

a {
  font-size: 0.8rem;
}
</style>