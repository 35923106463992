<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { GroupButton } from '@/js/import/importView.js';
import ServiceEnd from '@/views/ServiceEnd.vue';

const title = ref('Groupe de parole')

useHead({
  title: title.value,
  meta: [
    { name: 'description', content: 'Un groupe de parole animé par une doula est un espace de discussion et de soutien où les participants, souvent des futurs parents, des nouveaux parents, ou des personnes traversant des moments de transition liés à la maternité et à la parentalité, se réunissent pour partager leurs expériences, poser des questions et recevoir des conseils.' },
    { name: 'keywords', content: 'groupe de parole, doula, parentalité, soutien parental, soutien familial, soutien maternel, parentalité, parent, maman' },
  ],
})

</script>

<template>
  <article class="service-content" itemscope itemtype="https://schema.org/Article" itemprop="articleBody">
    <header class="container box-shadow-1 header" itemprop="headline">
      <h1>{{ title }}</h1>
      <p class="service-content__text_bold">
        Un <strong>groupe de parole</strong> animé par une <strong>doula</strong> est un <strong>espace de discussion
        et de soutien</strong> où les participants,souvent des futurs parents, des nouveaux parents, 
        ou des personnes traversant des moments de transition liésà la <strong>maternité</strong> et à la 
        <strong>parentalité</strong>, se réunissent pour partager leurs expériences,poser des questions et 
        recevoir des conseils.
         Voici les principales caractéristiques de ces groupes.
      </p>
    </header>

    <section class="container">

      <div class="logo">
          <img class="img-logo" src="@/assets/logo_nathalite_doula.png" alt="Logo de NatHalité Doula" />
        </div>


      <div class="service-content__block">
        <ol class="list">
          <li><strong>Animation et Encadrement par une Doula :</strong>
            <ul class="list">
              <li><strong>Rôle de la doula :</strong> La doula, une professionnelle formée pour soutenir émotionnellement, 
                physiquement et informativement les femmes enceintes et leurs familles, anime ces séances.
                Elle facilite les échanges, propose des thèmes de discussion et assure un cadre bienveillant et respectueux.</li>
              <li><strong>Expertise :</strong> Grâce à son expertise, la doula peut répondre à des questions, 
                fournir des informations précises sur la grossesse, l'accouchement, le post-partum et la parentalité,
                et offrir des conseils pratiques.</li>
            </ul>
          </li>
          <li><strong>Soutien Émotionnel et Partage d'Expériences :</strong>
            <ul class="list">
              <li><strong>Écoute active :</strong>Les participants sont encouragés à exprimer leurs sentiments,
                 leurs préoccupations et leurs expériences dans un environnement sans jugement.</li>
              <li><strong>Solidarité  :</strong> Le groupe permet de créer un réseau de soutien entre
                personnes vivant des situations similaires, renforçant ainsi le sentiment d'appartenance et 
                diminuant l'isolement.</li>
            </ul>
          </li>
          <li><strong>Thématiques Variées  : </strong>
            <ul class="list">
              <li><strong>Sujets abordés :</strong> Les discussions peuvent porter sur divers aspects de la maternité
                et de la parentalité, comme les changements corporels pendant la grossesse, les techniques de 
                gestion de la douleur pendant l'accouchement, l'allaitement, le retour à la maison avec le bébé, 
                et le bien-être mental et émotionnel des parents.</li>
              <li><strong>Adaptabilité :</strong>  Les thèmes peuvent être ajustés en 
                fonction des besoins et des demandes des participants.</li>
            </ul>
          </li>
            <li><strong>Cadre Bienveillant et Respectueux :</strong>
            <ul class="list">
              <li><strong>Confidentialité :</strong> Ce qui est partagé dans le groupe reste confidentiel,
                garantissant un espace sûr pour tous les participants.</li>
              <li><strong>Respect des opinions :</strong>Toutes les opinions et expériences sont respectées, 
                et la diversité des perspectives est valorisée.</li>
            </ul>
            </li>
            <li><strong>Activités et Interactions :</strong>

              <ul class="list">
              <li><strong>Discussions dirigées :</strong>La doula peut proposer des sujets de discussion spécifiques et encourager les participants à partager leurs points de vue et expériences.</li>
              <li><strong>Exercices pratiques  :</strong>Des exercices de relaxation, de respiration, ou des ateliers autour de la naissance peuvent être
                proposés pour aider les participants à se sentir plus préparés et confiants.</li>
              <li><strong>Questions-réponses :</strong>  Un temps est souvent réservé pour permettre aux participants
                de poser des questions spécifiques et recevoir des réponses éclairées.</li>
              </ul>


          </li>
        </ol>
      </div>

      <div class="service-content__block">
        <p>
          En somme, un <strong>groupe de parole</strong> animé par une <strong>doula</strong> offre un soutien
          précieux aux personnes en période de <strong>maternité</strong> et de <strong>parentalité</strong>, 
          leur permettant de partager leurs expériences, de se sentir soutenues et de recevoir des informations 
          et des conseils adaptés à leurs besoins. La doula vous redirige vers les professionnels de santé si besoin.
        </p>    
      </div>
    </section>

    <div class="service-end">
        <ServiceEnd 
          :price="0" 
          description="/" 
          infoText="Pour plus d'information, n'hésitez pas à nous contacter"
          backgroundGradient="rgba(236, 236, 236, 0.5), rgba(255, 201, 252, 0.5)"
        />
      </div>


  </article>
</template>

<script>


export default {
name: 'groupe_parole',
}

function handleContactClick() {
  handleClick('contact', router);
}
</script>

<style lang="scss" scoped>
@import '../../styles/services_item.scss';

.service-end {
  padding-bottom: rem(50);
}


</style>